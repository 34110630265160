<template>
  <router-view/>
  <confirm-popup ref="confirm" />
</template>

<script>
import ConfirmPopup from "@/components/dialogs/ConfirmPopup.vue";
import {useTheme} from "vuetify";
// eslint-disable-next-line no-unused-vars
import {mapActions, mapState} from "vuex";

export default {
  components: { ConfirmPopup },
  mounted(){
    this.$store.commit('initialiseStore')
    this.$root.$confirm = this.$refs.confirm.open;
    document.title = 'AlricAI'
  },
  methods:{
    ...mapActions({getUser: 'getUser'}),
    logout(){
      this.$store.commit('logout')
    }
  },
  data: () => ({
    theme: useTheme(),
  }),
  setup () {
    const theme = useTheme()

    return {
      theme,
      toggleTheme: () => theme.global.name.value = theme.global.current.value.dark ? 'light' : 'dark'
    }
  },
}
</script>

<style>
#app {
  font-family: "Inter:Light", "Helvetica Neue", Arial, sans-serif;
  font-weight: 100;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  /*font-variant: proportional-width;*/
  /*font-size: 0.8em;*/

  font-style: normal;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
