<template>
  <v-system-bar color="light-gray darken-3" >
    <v-col class="d-flex sm-gutters">
<!--      <div class="form-group small sm-gutters">
        <v-select v-if="page_name = 'Dashboard'"
            class="small pt-4"
            v-model="getSelectedExchange"
            :items="getConnections"
            item-title="name"
            item-value="id"
            density="compact"
            variant="plain"
            @update:modelValue="selectExchange"
        >
        </v-select>
      </div>-->
    </v-col>

    <div class="col-6">
      <v-btn-group class="fill-height">
        <v-btn size="x-small" variant="plain" to="/dashboard">Dashboard</v-btn>
        <v-btn size="x-small" variant="plain" to="/connections">Connections</v-btn>
        <v-btn size="x-small" variant="plain" to="/algos">Algos</v-btn>
        <v-btn size="x-small" variant="plain" to="/profile"><v-icon icon="mdi-cog"></v-icon></v-btn>
        <v-btn size="x-small" variant="plain" :onclick="this.$root.toggleTheme" ><v-icon :icon="this.$root.$vuetify.theme.name === 'light'
          ? 'mdi-weather-sunny'
          : 'mdi-weather-night'"></v-icon></v-btn>
        <a v-if="$store.state.email" @click="logout">
          <v-btn size="x-small" variant="plain" to="/algos">
            <v-icon icon="mdi-lock"></v-icon>
        </v-btn></a>
      </v-btn-group>
    </div>


  </v-system-bar>

  <div class="w-100 mt-5">

  </div>

</template>

<script>
// import { useTheme } from 'vuetify'

export default {
  name: 'NavBar',
  props: ['title', 'page_name'],
  data: () => ({

  }),
  watch: {
    group() {
      this.drawer = false
    }
  },
  methods: {
    logout() {
      this.$store.commit('logout')
      this.$router.push('/')
    },

  },
}
</script>
<style scoped>

</style>
