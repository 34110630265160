<template>
  <v-app>
    <NavBar :page_name="page_name"></NavBar>
    <v-main>

        <v-card>
          <div class="d-flex flex-row">
            <v-tabs
                v-model="tab"
                direction="vertical"
                color="primary"
                class="w-25"
            >
              <v-tab value="profile-1">
                <v-icon start>
                  mdi-account
                </v-icon>
                Profile
              </v-tab>
              <v-tab value="security-2">
                <v-icon start>
                  mdi-lock
                </v-icon>
                Security
              </v-tab>

              <v-tab value="notifications-3">
                <v-icon start>
                  mdi-access-point
                </v-icon>
                Notifications
              </v-tab>
              <v-tab value="settings-5">
                <v-icon start>
                  mdi-cog
                </v-icon>
                Settings
              </v-tab>
            </v-tabs>
            <v-window v-model="tab" class="w-75">
              <v-window-item value="profile-1">
                <v-card flat>
                  <v-card-text>
                    <h5 class="card-title">General Information</h5>
                    <div class="settings-profile">
                      <form>
<!--                        <img src="img/avatar.svg" alt="avatar" />
                        <div class="custom-file">
                          <input type="file" class="form-control" />
                        </div>-->

                        <div class="row mt-4">
                          <div class="col-md-6">
                            <label htmlFor="formFirst">First name</label>
                            <input
                                id="formFirst"
                                type="text"
                                class="form-control"
                                placeholder="First name"
                            />
                          </div>
                          <div class="col-md-6">
                            <label htmlFor="formLast">Last name</label>
                            <input
                                id="formLast"
                                type="text"
                                class="form-control"
                                placeholder="Last name"
                            />
                          </div>
                          <div class="col-md-6">
                            <label htmlFor="emailAddress">Email</label>
                            <input
                                id="emailAddress"
                                type="text"
                                class="form-control"
                                placeholder="Enter your email"
                            />
                          </div>
                          <div class="col-md-6">
                            <label htmlFor="phoneNumber">Phone</label>
                            <input
                                id="phoneNumber"
                                type="text"
                                class="form-control"
                                placeholder="Enter phone number"
                            />
                          </div>
                          <div class="col-md-6">
                            <label htmlFor="selectLanguage">Language</label>
                            <select id="selectLanguage" class="form-select">
                              <option defaultValue>English</option>
                              <option>Mandarin Chinese</option>
                              <option>Spanish</option>
                              <option>Arabic</option>
                              <option>Russian</option>
                            </select>
                          </div>
                          <div class="col-md-6">
                            <label htmlFor="selectCurrency">Currency</label>
                            <select id="selectCurrency" class="form-select">
                              <option defaultValue>USD</option>
                              <option>EUR</option>
                              <option>GBP</option>
                              <option>CHF</option>
                            </select>
                          </div>
                          <div class="col-md-12">
                            <input type="submit" value="Update" />
                          </div>
                        </div>
                      </form>
                    </div>
                  </v-card-text>
                </v-card>
              </v-window-item>
              <v-window-item value="security-2">
                <v-card flat>
                  <v-card-text>
                    <h5 class="card-title">Security Information</h5>
                    <div class="settings-profile">
                      <form>
                        <div class="row">
                          <div class="col-md-6">
                            <label htmlFor="currentPass">
                              Current password
                            </label>
                            <input
                                id="currentPass"
                                type="text"
                                class="form-control"
                                placeholder="Enter your password"
                            />
                          </div>
                          <div class="col-md-6">
                            <label htmlFor="newPass">New password</label>
                            <input
                                id="newPass"
                                type="text"
                                class="form-control"
                                placeholder="Enter new password"
                            />
                          </div>
                          <div class="col-md-6">
                            <label htmlFor="securityOne">
                              Security questions #1
                            </label>
                            <select id="securityOne" class="form-select">
                              <option defaultValue>
                                What was the name of your first pet?
                              </option>
                              <option>What's your Mother's middle name?</option>
                              <option>
                                What was the name of your first school?
                              </option>
                              <option>
                                Where did you travel for the first time?
                              </option>
                            </select>
                          </div>
                          <div class="col-md-6">
                            <label htmlFor="securityAnsOne">Answer</label>
                            <input
                                id="securityAnsOne"
                                type="text"
                                class="form-control"
                                placeholder="Enter your answer"
                            />
                          </div>
                          <div class="col-md-6">
                            <label htmlFor="securityTwo">
                              Security questions #2
                            </label>
                            <select id="securityTwo" class="form-select">
                              <option defaultValue>Choose...</option>
                              <option>
                                What was the name of your first pet?
                              </option>
                              <option>What's your Mother's middle name?</option>
                              <option>
                                What was the name of your first school?
                              </option>
                              <option>
                                Where did you travel for the first time?
                              </option>
                            </select>
                          </div>
                          <div class="col-md-6">
                            <label htmlFor="securityAnsTwo">Answer</label>
                            <input
                                id="securityAnsTwo"
                                type="text"
                                class="form-control"
                                placeholder="Enter your answer"
                            />
                          </div>
                          <div class="col-md-6">
                            <label htmlFor="securityThree">
                              Security questions #3
                            </label>
                            <select id="securityThree" class="form-select">
                              <option defaultValue>Choose...</option>
                              <option>
                                What was the name of your first pet?
                              </option>
                              <option>What's your Mother's middle name?</option>
                              <option>
                                What was the name of your first school?
                              </option>
                              <option>
                                Where did you travel for the first time?
                              </option>
                            </select>
                          </div>
                          <div class="col-md-6">
                            <label htmlFor="securityFore">Answer</label>
                            <input
                                id="securityFore"
                                type="text"
                                class="form-control"
                                placeholder="Enter your answer"
                            />
                          </div>
                          <div class="col-md-12">
                            <input type="submit" value="Update" />
                          </div>
                        </div>
                      </form>
                    </div>
                  </v-card-text>
                </v-card>
              </v-window-item>
              <v-window-item value="notifications-3">
                <v-card flat>
                  <v-card-text>
                    <h5 class="card-title">Notifications</h5>
                    <div class="settings-notification">
                      <ul>
                        <li>
                          <div class="notification-info">
                            <p>Update price</p>
                            <span> Get the update price in your dashboard </span>
                          </div>
                          <div class="custom-control form-check">
                            <input
                                type="checkbox"
                                class="form-check-input"
                                id="notification1"
                            />
                            <label
                                class="form-check-label"
                                htmlFor="notification1"
                            ></label>
                          </div>
                        </li>
                        <li>
                          <div class="notification-info">
                            <p>2FA</p>
                            <span>
                            Unable two factor authentication service
                          </span>
                          </div>
                          <div class="custom-control form-check">
                            <input
                                type="checkbox"
                                class="form-check-input"
                                id="notification2"
                                checked
                            />
                            <label
                                class="form-check-label"
                                htmlFor="notification2"
                            ></label>
                          </div>
                        </li>
                        <li>
                          <div class="notification-info">
                            <p>Latest news</p>
                            <span>Get the latest news in your mail</span>
                          </div>
                          <div class="custom-control form-check">
                            <input
                                type="checkbox"
                                class="form-check-input"
                                id="notification3"
                            />
                            <label
                                class="form-check-label"
                                htmlFor="notification3"
                            ></label>
                          </div>
                        </li>
                        <li>
                          <div class="notification-info">
                            <p>Email Service</p>
                            <span>Get security code in your mail</span>
                          </div>
                          <div class="custom-control form-check">
                            <input
                                type="checkbox"
                                class="form-check-input"
                                id="notification4"
                                checked
                            />
                            <label
                                class="form-check-label"
                                htmlFor="notification4"
                            ></label>
                          </div>
                        </li>
                        <li>
                          <div class="notification-info">
                            <p>Phone Notify</p>
                            <span>
                            Get transition notification in your phone
                          </span>
                          </div>
                          <div class="custom-control form-check">
                            <input
                                type="checkbox"
                                class="form-check-input"
                                id="notification5"
                                checked
                            />
                            <label
                                class="form-check-label"
                                htmlFor="notification5"
                            ></label>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </v-card-text>
                </v-card>
              </v-window-item>
              <v-window-item value="settings-5">
                <v-card flat>
                  <v-card-text>
                    <h5 class="card-title">Create API Key</h5>
                    <div class="row">
                      <div class="col-md-6">
                        <label htmlFor="generateKey"> Generate key name </label>
                        <input
                            id="generateKey"
                            type="text"
                            class="form-control"
                            placeholder="Enter your key name"
                        />
                      </div>
                      <div class="col-md-6">
                        <label htmlFor="rewritePassword">
                          Confirm password
                        </label>
                        <input
                            id="rewritePassword"
                            type="password"
                            class="form-control"
                            placeholder="Confirm your password"
                        />
                      </div>
                      <div class="col-md-12">
                        <input type="submit" value="Create API key" />
                      </div>
                    </div>
                    <h5 class="card-title">Your API Keys</h5>
                    <div class="wallet-history">
                      <table class="table">
                        <thead>
                        <tr>
                          <th>No.</th>
                          <th>Key</th>
                          <th>Status</th>
                          <th>Action</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                          <td>1</td>
                          <td>zRmWVcrAZ1C0RZkFMu7K5v0KWC9jUJLt</td>
                          <td>
                            <div class="custom-control form-check">
                              <input
                                  type="checkbox"
                                  class="form-check-input"
                                  id="apiStatus1"
                                  checked
                              />
                              <label
                                  class="form-check-label"
                                  htmlFor="apiStatus1"
                              ></label>
                            </div>
                          </td>
                          <td>
                            <i class="icon ion-md-trash"></i>
                          </td>
                        </tr>
                        <tr>
                          <td>2</td>
                          <td>Rv5dgnKdmVPyHwxeExBYz8uFwYQz3Jvg</td>
                          <td>
                            <div class="custom-control form-check">
                              <input
                                  type="checkbox"
                                  class="form-check-input"
                                  id="apiStatus2"
                              />
                              <label
                                  class="form-check-label"
                                  htmlFor="apiStatus2"
                              ></label>
                            </div>
                          </td>
                          <td>
                            <i class="icon ion-md-trash"></i>
                          </td>
                        </tr>
                        <tr>
                          <td>3</td>
                          <td>VxEYIs1HwgmtKTUMA4aknjSEjjePZIWu</td>
                          <td>
                            <div class="custom-control form-check">
                              <input
                                  type="checkbox"
                                  class="form-check-input"
                                  id="apiStatus3"
                              />
                              <label
                                  class="form-check-label"
                                  htmlFor="apiStatus3"
                              ></label>
                            </div>
                          </td>
                          <td>
                            <i class="icon ion-md-trash"></i>
                          </td>
                        </tr>
                        <tr>
                          <td>4</td>
                          <td>M01DueJ4x3awI1SSLGT3CP1EeLSnqt8o</td>
                          <td>
                            <div class="custom-control form-check">
                              <input
                                  type="checkbox"
                                  class="form-check-input"
                                  id="apiStatus4"
                              />
                              <label
                                  class="form-check-label"
                                  htmlFor="apiStatus4"
                              ></label>
                            </div>
                          </td>
                          <td>
                            <i class="icon ion-md-trash"></i>
                          </td>
                        </tr>
                        </tbody>
                      </table>
                    </div>
                  </v-card-text>
                </v-card>
              </v-window-item>
            </v-window>
          </div>
        </v-card>

    </v-main>
  </v-app>
</template>
<script>
import NavBar from "@/components/NavBar.vue";

export default {
  name: 'ProfileView',
  components: {NavBar},
  data: () => ({
    page_name: 'Profile Settings',
    tab: 'profile-1',
  }),
}
</script>
