import HttpRequest from './http_request'
import {waitFor} from "@babel/core/lib/gensync-utils/async";


class GuardianProvider extends HttpRequest {
    constructor () {
        // api api
        super()
        // this.alricDb = new localStorageDB("alricDb", localStorage)
    }

    /**
     * Saves connection details from the form
     * @param item
     * @param formData
     * @returns {Promise<void>}
     */
    async saveConnection(item,formData){
        let data = JSON.stringify({
            //"data": {
               // "type": item,
               // "attributes": {
                    "name": formData.name,
                    "exchange": formData.exchange_id,
                    "api_key": formData.api_key,
                    "trade_type": formData.trade_type,
                    "api_secret": formData.api_secret,
               // }
            //}
        });

        const {resp} = await this.request('LOGIN','/v1/connections', data)
        // console.log(resp);
    }

    /**
     * Get configured connections for this user
     *
     * @returns {Promise<*>}
     */
    async getConnections () {
        if( undefined === sessionStorage.getItem('access_token') || sessionStorage.getItem('access_token') === null ){
            waitFor(8)
        }
        const {data} = await this.request('GET','/v1/connections')
       // console.log('WHERE',data.data)
        return data.data
    }

    /**
     * Saves form data 'Manage Symbols' modal to the API
     *
     * @param connectionId
     * @param symbolsArray
     * @returns {Promise<void>}
     */
    async saveConnectionSymbols(connectionId,symbolsArray){

       // let resp =
            await this.request('DELETE', '/v1/active-symbols/'+connectionId)
        // console.log(resp);
        this.$alricDb.deleteRows("connection_symbols", {connection_id: connectionId});
        this.$alricDb.commit();
        let symbols = JSON.parse(symbolsArray)

        let i = 0;
        do{
// console.log(symbols[i].symbol_id)
            let data = JSON.stringify({
                "data": {
                    "type": "connection-symbols",
                    "attributes": {
                        "symbol_id": symbols[i].symbol_id,
                        "connection_id": symbols[i].connection_id,
                        "active": false
                    }
                }
            });
            //const {resp} =
           await this.request('POST','/v1/connection-symbols', data)

            this.$alricDb.insert("connection_symbols", {
                id: symbols[i].id,
                connection_id: symbols[i].connection_id,
                symbol_id: symbols[i].symbol_id,
                symbol_name: symbols[i].symbol_name,
                active: false
            });

            // console.log(resp);
            i++
        } while (i < symbols.length)
        this.$alricDb.commit();
        /*console.log('SYMBOLS',symbols)
        console.log('ALL-FORM',connectionId, symbolsArray)*/
    }

    /**
     * Returns the list of active symbols for a connection
     *
     * @todo This really should use other than a numeric ID (uuid - ?)
     * @param connection
     * @returns {Promise<*>}
     */
    async getConnectionSymbols (connection) {

        const {data} = await this.request('GET','/v1/connection-symbols/'+connection) // connection-symbols?filter[connection_id]=16
        return data.data

    }

    /**
     * Get list of exchanges from the API
     *  We do it this way instead of ccxt so we can manage which exchanges are enabled
     * @returns {Promise<*>}
     */
    async getExchanges () {
        const {data} = await this.request('GET','/v1/exchange')

        return data.data
    }

    /**
     * Get configured connections for this user
     *
     * @returns {Promise<*>}
     */
    async getSymbols (exchangeID) {
        if( undefined === sessionStorage.getItem('access_token') || sessionStorage.getItem('access_token') === null ){
            waitFor(5)
        }
        const {data} = await this.request('GET', '/v1/symbol/' + exchangeID);

        return data.data
    }

    /**
     * Get configured connections for this user
     *
     * @returns {Promise<*>}
     */
    async getAlgos () {
        if( undefined === sessionStorage.getItem('access_token') || sessionStorage.getItem('access_token') === null ){
            waitFor(5)
        }
        const {data} = await this.request('GET', '/v1/algo');

        return data.data
    }

    /**
     * Returns the full (lengthy) list of symbols supported by the exchange
     * @returns {Promise<*>}
     * @param exchangeId
     */
    async getExchangeSymbols (exchangeId) {
       // console.log('EXCHANGE-ID',exchangeId )
        if( undefined !== exchangeId &&  exchangeId > 0 ) {
            const {data} = await this.request('GET', '/v1/symbols?filter[exchange_id]=' + exchangeId) // returns JSON array()
            return data.data
        }
        return [];

    }

    /**
     * Saves MarketMaker Form to API - which begins the build/deploy of the Docker to hosting
     * @todo This API endpoint should return a unique ID so we can call it to get websocket details etc..
     * @param item
     * @param formData
     * @returns {Promise<void>}
     */
    async addScalper(item,formData){
        let data = JSON.stringify({
            "name": formData.name,
            "algo_config" : formData.options,
            "connection_id": formData.connection_id,
            "symbol_id": formData.symbol,
        });

        const {resp} = await this.request('POST','/v1/algo', data)
        // console.log(resp);
    }
    async updateScalper(item,formData){
        let data = JSON.stringify({"algo_config" : formData.options});

        const {resp} = await this.request('PATCH','/v1/algo/'+formData.algo_id, data)
        // console.log(resp);
    }

    async controlAlgo(algoId, action){

        let data = {algo_id: algoId, action: action};

        const {resp} = await this.request('POST','/v1/control/algos', data)

    }

    async deleteConnection(item){
        const {resp} = await this.request('DELETE','/v1/connections/'+item)
        // console.log(resp);
    }

    async deleteAlgo(item){
        const {resp} = await this.request('DELETE','/v1/algo/'+item)
        // console.log(resp);
    }




}

export default GuardianProvider
