<template>
  <div class="market-trade">
    <v-tabs
        v-model="tab"
    >
      <v-tab value="limit">Limit</v-tab>
      <v-tab value="market">Market</v-tab>
      <v-tab value="stop-limit">Stop Limit</v-tab>
      <v-tab value="ladder">Ladder</v-tab>
      <v-tab value="chaser">Chaser</v-tab>
    </v-tabs>
    <div class="pa-3">
      <v-window v-model="tab">
        <v-window-item value="limit">
          <div class="d-flex justify-content-between mt-3">
            <div class="market-trade-buy">
              <form @submit.stop.prevent="submit" id="limit-buy">
                <v-text-field :label="base_currency" variant="outlined" density="compact" class="mb-0"></v-text-field>
                <v-text-field :label="term_currency" variant="outlined" density="compact" class="mt-0"></v-text-field>
                <v-row>
                  <v-col cols="5" sm="12" md="5" >
                    <ul class="market-trade-list float-end ml-3">
                      <li>
                        <v-chip
                            :ripple="false"
                            size="small"
                            link
                            color="green"
                            text-color="white">
                          25%
                        </v-chip>
                      </li>
                      <li>
                        <v-chip
                            :ripple="false"
                            size="small"
                            link
                            color="green"
                            text-color="white">
                          50%
                        </v-chip>

                      </li>
                      <li>
                        <v-chip
                            :ripple="false"
                            size="small"
                            link
                            color="green"
                            text-color="white">
                          75%
                        </v-chip>
                      </li>
                      <li>
                        <v-chip
                            :ripple="false"
                            size="small"
                            link
                            color="green"
                            text-color="white">
                          100%
                        </v-chip>
                      </li>
                    </ul>
                  </v-col>
                  <v-col cols="7" sm="12" md="7">
                    <p class="font-weight-bold">Available: <span class="w-25" > {{  term_currency  }} </span><span class="w-25" id="{{  term_currency  }}_available_balance">0</span></p>
                    <p class="font-weight-bold">Margin: <span class="w-25" >{{  term_currency  }} </span><span class="w-25"  id="{{  term_currency  }}_available_margin">0</span></p>
                    <p class="font-weight-bold">Fee: <span class="w-25" >{{  term_currency  }} </span><span class="w-25"  id="fee_calculated">0</span></p>
                  </v-col>
                </v-row>
                <v-row class="d-flex">
                  <v-col col="6">
                    <v-btn
                        color="green"
                        class="mt-4"
                        block
                        @click="btnEvent"
                    >
                      Buy
                    </v-btn>
                  </v-col>
                  <v-col col="6">
                    <v-btn
                        color="red"
                        class="mt-4"
                        block
                        @click="btnEvent"
                    >
                      Sell
                    </v-btn>
                  </v-col>
                </v-row>


              </form>
            </div>
<!--            <div class="market-trade-sell">
              <form @submit.stop.prevent="submit" id="limit-sell">
                <v-text-field :label="base_currency" variant="outlined" density="compact" class="mb-0"></v-text-field>
                <v-text-field :label="term_currency" variant="outlined" density="compact" class="mt-0"></v-text-field>
                <ul class="market-trade-list float-end ml-3">
                  <li>
                    <v-chip
                        :ripple="false"
                        size="small"
                        link
                        color="green"
                        text-color="white">
                      25%
                    </v-chip>
                  </li>
                  <li>
                    <v-chip
                        :ripple="false"
                        size="small"
                        link
                        color="green"
                        text-color="white">
                      50%
                    </v-chip>

                  </li>
                  <li>
                    <v-chip
                        :ripple="false"
                        size="small"
                        link
                        color="green"
                        text-color="white">
                      75%
                    </v-chip>
                  </li>
                  <li>
                    <v-chip
                        :ripple="false"
                        size="small"
                        link
                        color="green"
                        text-color="white">
                      100%
                    </v-chip>
                  </li>
                </ul>
                <p>Available: <span>0 {{  base_currency  }} </span></p>
                <p>Margin: <span>0 {{  base_currency  }} </span></p>
                <p>Fee: <span>0 {{  base_currency  }} </span></p>
                <v-btn
                    color="red"
                    class="mt-4"
                    block
                    @click="btnEvent"
                >
                  Sell
                </v-btn>
                &lt;!&ndash;              <button class="btn sell">Sell</button>&ndash;&gt;
              </form>
            </div>-->
          </div>
        </v-window-item>
        <v-window-item value="market">
          <div class="d-flex justify-content-between mt-3">
            <div class="market-trade-buy">
              <form @submit.stop.prevent="submit" id="stop-limit-buy">

                <v-text-field :label="base_currency" variant="outlined" density="compact" class="mb-0"></v-text-field>
                <v-text-field :label="term_currency" variant="outlined" density="compact" class="mt-0"></v-text-field>
                <v-row>
                  <v-col cols="5" sm="12" md="5" >
                    <ul class="market-trade-list float-end ml-3">
                      <li>
                        <v-chip
                            :ripple="false"
                            size="small"
                            link
                            color="green"
                            text-color="white">
                          25%
                        </v-chip>
                      </li>
                      <li>
                        <v-chip
                            :ripple="false"
                            size="small"
                            link
                            color="green"
                            text-color="white">
                          50%
                        </v-chip>

                      </li>
                      <li>
                        <v-chip
                            :ripple="false"
                            size="small"
                            link
                            color="green"
                            text-color="white">
                          75%
                        </v-chip>
                      </li>
                      <li>
                        <v-chip
                            :ripple="false"
                            size="small"
                            link
                            color="green"
                            text-color="white">
                          100%
                        </v-chip>
                      </li>
                    </ul>
                  </v-col>
                  <v-col cols="7" sm="12" md="7">
                    <p class="font-weight-bold">Available: <span class="w-25" > {{  term_currency  }} </span><span class="w-25" id="{{  term_currency  }}_available_balance">0</span></p>
                    <p class="font-weight-bold">Margin: <span class="w-25" >{{  term_currency  }} </span><span class="w-25"  id="{{  term_currency  }}_available_margin">0</span></p>
                    <p class="font-weight-bold">Fee: <span class="w-25" >{{  term_currency  }} </span><span class="w-25"  id="fee_calculated">0</span></p>
                  </v-col>
                </v-row>
                <v-btn
                    color="green"
                    class="mt-4"
                    block
                    @click="btnEvent"
                >
                  Buy
                </v-btn>
              </form>
            </div>
<!--            <div class="market-trade-sell">
              <form @submit.stop.prevent="submit" id="stop-limit-sell">
                <v-text-field :label="base_currency" variant="outlined" density="compact" class="mb-0"></v-text-field>
                <v-text-field :label="term_currency" variant="outlined" density="compact" class="mt-0"></v-text-field>
                <ul class="market-trade-list float-end ml-3">
                  <li>
                    <v-chip
                        :ripple="false"
                        size="small"
                        link
                        color="green"
                        text-color="white">
                      25%
                    </v-chip>
                  </li>
                  <li>
                    <v-chip
                        :ripple="false"
                        size="small"
                        link
                        color="green"
                        text-color="white">
                      50%
                    </v-chip>

                  </li>
                  <li>
                    <v-chip
                        :ripple="false"
                        size="small"
                        link
                        color="green"
                        text-color="white">
                      75%
                    </v-chip>
                  </li>
                  <li>
                    <v-chip
                        :ripple="false"
                        size="small"
                        link
                        color="green"
                        text-color="white">
                      100%
                    </v-chip>
                  </li>
                </ul>
                <p>Available: <span>0 {{  term_currency  }} </span></p>
                <p>Margin: <span>0 {{  term_currency  }} </span></p>
                <p>Fee: <span>0 {{  term_currency  }} </span></p>
                <v-btn
                    color="red"
                    class="mt-4"
                    block
                    @click="btnEvent"
                >
                  Sell
                </v-btn>
              </form>
            </div>-->
          </div>
        </v-window-item>
        <v-window-item value="stop-limit">
          <div class="d-flex justify-content-between mt-3">
            <div class="market-trade-buy">
              <form @submit.stop.prevent="submit" id="stop-limit-buy">
                <v-row class="d-flex">
                  <v-col col="6">
                    <v-row class="d-flex">
                      <v-col col="6">Stop Price</v-col>
                      <v-col col="6">
                        <v-text-field name="stop_price_base" :label="base_currency" variant="outlined" density="compact" class="mb-0"></v-text-field>
                        <v-text-field name="stop_price_term" :label="term_currency" variant="outlined" density="compact" class="mt-0"></v-text-field>
                      </v-col>
                    </v-row>

                  </v-col>
                  <v-col col="6">
                    <v-row>
                      <v-col col="3">Limit Price </v-col>
                      <v-col col="9">
                        <v-text-field name="limit_price_base" :label="base_currency" variant="outlined" density="compact" class="mt-0"></v-text-field>
                        <v-text-field name="limit_price_term" :label="term_currency" variant="outlined" density="compact" class="mt-0"></v-text-field>
                      </v-col>
                    </v-row>
                    <span>

                    </span>

                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="10" class="d-inline-flex mx-auto">
                    <v-radio-group
                        v-model="time_in_force"
                        inline
                        variant="outlined" density="compact"
                    >
                      <v-radio
                          label="Good Till Cancel"
                          value="GTC"
                      ></v-radio>
                      <v-radio
                          label="Immediate Or Cancel"
                          value="IOC"
                      ></v-radio>
                      <v-radio
                          label="Fill or Kill"
                          value="FOK"
                      ></v-radio>
                    </v-radio-group>
                  </v-col>

                </v-row>
                <v-row>
                  <v-col cols="5" sm="12" md="5" >
                    <ul class="market-trade-list float-end ml-3">
                      <li>
                        <v-chip
                            :ripple="false"
                            size="small"
                            link
                            color="green"
                            text-color="white">
                          25%
                        </v-chip>
                      </li>
                      <li>
                        <v-chip
                            :ripple="false"
                            size="small"
                            link
                            color="green"
                            text-color="white">
                          50%
                        </v-chip>

                      </li>
                      <li>
                        <v-chip
                            :ripple="false"
                            size="small"
                            link
                            color="green"
                            text-color="white">
                          75%
                        </v-chip>
                      </li>
                      <li>
                        <v-chip
                            :ripple="false"
                            size="small"
                            link
                            color="green"
                            text-color="white">
                          100%
                        </v-chip>
                      </li>
                    </ul>
                  </v-col>
                  <v-col cols="7" sm="12" md="7">
                    <p class="font-weight-bold">Available: <span class="w-25" > {{  term_currency  }} </span><span class="w-25" id="{{  term_currency  }}_available_balance">0</span></p>
                    <p class="font-weight-bold">Margin: <span class="w-25" >{{  term_currency  }} </span><span class="w-25"  id="{{  term_currency  }}_available_margin">0</span></p>
                    <p class="font-weight-bold">Fee: <span class="w-25" >{{  term_currency  }} </span><span class="w-25"  id="fee_calculated">0</span></p>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="6">
                    <v-btn
                        color="green"
                        class="mt-4"
                        block
                        @click="btnEvent"
                    >
                      Buy
                    </v-btn>
                  </v-col>
                  <v-col cols="6">
                    <v-btn
                        color="red"
                        class="mt-4"
                        block
                        @click="btnEvent"
                    >
                      Sell
                    </v-btn>
                  </v-col>
                </v-row>


              </form>
            </div>
<!--            <div class="market-trade-sell">
              <form @submit.stop.prevent="submit" id="stop-limit-sell">
                <v-text-field :label="base_currency" variant="outlined" density="compact" class="mb-0"></v-text-field>
                <v-text-field :label="term_currency" variant="outlined" density="compact" class="mt-0"></v-text-field>
                <ul class="market-trade-list float-end ml-3">
                  <li>
                    <v-chip
                        :ripple="false"
                        size="small"
                        link
                        color="green"
                        text-color="white">
                      25%
                    </v-chip>
                  </li>
                  <li>
                    <v-chip
                        :ripple="false"
                        size="small"
                        link
                        color="green"
                        text-color="white">
                      50%
                    </v-chip>

                  </li>
                  <li>
                    <v-chip
                        :ripple="false"
                        size="small"
                        link
                        color="green"
                        text-color="white">
                      75%
                    </v-chip>
                  </li>
                  <li>
                    <v-chip
                        :ripple="false"
                        size="small"
                        link
                        color="green"
                        text-color="white">
                      100%
                    </v-chip>
                  </li>
                </ul>
                <p>Available: <span>0 {{  term_currency  }} </span></p>
                <p>Margin: <span>0 {{  term_currency  }} </span></p>
                <p>Fee: <span>0 {{  term_currency  }} </span></p>
                <v-btn
                    color="red"
                    class="mt-4"
                    block
                    @click="btnEvent"
                >
                  Sell
                </v-btn>
              </form>
            </div>-->
          </div>
        </v-window-item>
        <v-window-item value="ladder">
          <div class="d-flex justify-content-between mt-3">
            <div class="market-trade-buy">
              <LadderForm></LadderForm>
            </div>
          </div>
        </v-window-item>
        <v-window-item value="chaser">
          <div class="d-flex justify-content-between mt-3">
            <div class="market-trade-buy">
              <form @submit.stop.prevent="submit" id="ladder-buy-form">
                <v-text-field :label="'Size '+ term_currency" variant="outlined" density="compact" class="mb-0"></v-text-field>
                <v-row>
                  <v-col cols="5" >
                    <ul class="market-trade-list float-end ml-3">
                      <li>
                        <v-chip
                            :ripple="false"
                            size="small"
                            link
                            color="green"
                            text-color="white">
                          25%
                        </v-chip>
                      </li>
                      <li>
                        <v-chip
                            :ripple="false"
                            size="small"
                            link
                            color="green"
                            text-color="white">
                          50%
                        </v-chip>

                      </li>
                      <li>
                        <v-chip
                            :ripple="false"
                            size="small"
                            link
                            color="green"
                            text-color="white">
                          75%
                        </v-chip>
                      </li>
                      <li>
                        <v-chip
                            :ripple="false"
                            size="small"
                            link
                            color="green"
                            text-color="white">
                          100%
                        </v-chip>
                      </li>
                    </ul>
                  </v-col>
                  <v-col cols="7">
                    <p>Available: <span>0 {{  term_currency  }} </span></p>
                    <p>Margin: <span>0 {{  term_currency  }} </span></p>
                    <p>Fee: <span>0 {{  term_currency  }} </span></p>
                  </v-col>
                </v-row>
                <v-slider
                    v-model="chase_to"
                    :max="1.00"
                    :step="0.01"

                    thumb-color="green"
                    thumb-label="always"
                    label="Chase To"

                    class="ma-4"
                >

                  <template v-slot:append>
                    <v-icon icon="mdi-plus-minus-variant" size="small"></v-icon>
                  </template>
                </v-slider>
                <v-row class="d-flex flex-fill ma-3">
                  <div class="col-6">
                    <v-icon size="small"
                            end
                            color="green"
                            icon="mdi-arrow-collapse-up"
                    ></v-icon>
                    21244.0</div>
                  <div class="col-6">
                    21244.0
                    <v-icon size="small"
                            end
                            color="red"
                            icon="mdi-arrow-collapse-down"
                    ></v-icon>
                  </div>
                </v-row>



                <v-row>
                  <v-col
                      cols="12"
                      sm="5"
                      md="5"
                  >
                    <v-switch
                        v-model="chase_options"
                        label="Until Fill/Cancel"
                        color="info"
                        value="fill_cancel_switch"
                        hide-details
                    ></v-switch>

                  </v-col>
                  <v-col
                      cols="12"
                      sm="4"
                      md="4"
                  >
                    <v-switch
                        v-model="chase_options"
                        label="Reduce"
                        color="info"
                        value="reduce_switch"
                        hide-details
                    ></v-switch>

                  </v-col>
                  <v-col
                      cols="12"
                      sm="3"
                      md="3"
                  >
                    <v-switch
                        v-model="chase_options"
                        label="Stop"
                        color="info"
                        value="stop_switch"
                        hide-details
                    ></v-switch>

                  </v-col>
                </v-row>
                <v-row class="d-flex">
                  <v-col col="6">
                    <v-btn
                        color="green"
                        class="mt-4"
                        block
                        @click="btnEvent"
                    >
                      Buy
                    </v-btn>
                  </v-col>
                  <v-col col="6">
                    <v-btn
                        color="red"
                        class="mt-4"
                        block
                        @click="btnEvent"
                    >
                      Sell
                    </v-btn>
                  </v-col>
                </v-row>


              </form>
            </div>
<!--            <div class="market-trade-sell">
              <form @submit.stop.prevent="submit" id="ladder-sell-form">
                <v-text-field :label="base_currency" variant="outlined" density="compact" class="mb-0"></v-text-field>
                <v-text-field :label="term_currency" variant="outlined" density="compact" class="mt-0"></v-text-field>
                <ul class="market-trade-list float-end ml-3">
                  <li>
                    <v-chip
                        :ripple="false"
                        size="small"
                        link
                        color="green"
                        text-color="white">
                      25%
                    </v-chip>
                  </li>
                  <li>
                    <v-chip
                        :ripple="false"
                        size="small"
                        link
                        color="green"
                        text-color="white">
                      50%
                    </v-chip>

                  </li>
                  <li>
                    <v-chip
                        :ripple="false"
                        size="small"
                        link
                        color="green"
                        text-color="white">
                      75%
                    </v-chip>
                  </li>
                  <li>
                    <v-chip
                        :ripple="false"
                        size="small"
                        link
                        color="green"
                        text-color="white">
                      100%
                    </v-chip>
                  </li>
                </ul>
                <p>Available: <span><span id="trade_available_balance">0</span> {{  term_currency  }} </span></p>
                <p>Margin: <span><span id="trade_margin_balance">0</span> {{  term_currency  }} </span></p>
                <p>Fee: <span><span id="trade_fee">0</span> {{  term_currency  }} </span></p>
                <v-btn
                    color="red"
                    class="mt-4"
                    block
                    @click="btnEvent"
                >
                  Sell
                </v-btn>
              </form>
            </div>-->
          </div>
        </v-window-item>
      </v-window>
    </div>


  </div>
</template>

<script>
import LadderForm from "@/components/forms/LadderForm.vue";

export default {
  components: {LadderForm},
  props: [
      'base_currency', 'term_currency', 'trading_connection', 'trading_symbol'
  ],
  data: () => ({
    reveal: false,
    panel: [],
    tab: null,
    chase_to: 0.31,
    chase_options : [],
    time_in_force : 'GTC'
  }),
  methods: {
    submit() {
      this.$router.push("wallet");
    },
    btnEvent() {
      return true
    }
  },
};
</script>
