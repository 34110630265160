<template>
  <div class="row sm-gutters">
    <v-row class="text-center">
      <v-col cols="3">

          <MarketPairs></MarketPairs>


      </v-col>
      <v-col cols="6">
        <TradingChart></TradingChart>
        <MarketTrade :term_currency="getTermCurrency" :base_currency="getBaseCurrency" :trading_connection="trading_connection" :trading_symbol="trading_symbol"></MarketTrade>
      </v-col>
      <v-col cols="3">
        <OrderBook></OrderBook>
        <MarketHistory :exchange="trading_exchange" :symbol_name="trading_symbol"></MarketHistory>
      </v-col>
      <v-col cols="12">
        <HistoryOrder></HistoryOrder>
      </v-col>

    </v-row>
  </div>
</template>

<script>

import TradingChart from "@/components/TradingChart.vue";
import MarketPairs from "@/components/blocks/MarketPairs.vue";
import OrderBook from "@/components/blocks/OrderBook.vue";
import HistoryOrder from "@/components/blocks/HistoryOrder.vue";
import MarketTrade from "@/components/blocks/MarketTrade.vue";
import MarketHistory from "@/components/blocks/MarketHistory.vue";

export default {
  name: 'TradingDashboard',
  components: {MarketHistory, MarketTrade, HistoryOrder, OrderBook, TradingChart, MarketPairs },

  data: () => ({
    currencies: {
      "base_currency": 'BTC',
      "term_currency": 'USDT',
    },
    // trading_connection : localStorage.getItem('trading_connection'),
    // trading_exchange : localStorage.getItem('trading_exchange').toUpperCase(),
    trading_symbol : localStorage.getItem('trading_symbol'),
    trading_connection: function (){
      let cxn = JSON.parse(localStorage.getItem('trading_connection'))
      return cxn.name
    },
    trading_exchange: function (){
      let cxn = JSON.parse(localStorage.getItem('trading_exchange'))
      return cxn.name.toUpperCase()
    },

  }),
  computed: {
    getBaseCurrency() {
      return this.$store.state.trading_symbol.symbol.split('/')[0]
    },
    getTermCurrency() {
      return this.$store.state.trading_symbol.symbol.split('/')[1]
    }
  }
}
</script>
