<template>
  <v-card
      class="mx-auto ma-1"
      min-width="10%"
      max-width="40%"
  >
    <v-card-text>
      <h3>{{ friendly_name }}</h3>
      <div>{{ exchange_name }} using {{ connection_name }}</div>
      <p class="text-h5 text--primary">
        {{ symbol_name }}
      </p>
      <v-row>

        <v-col>
          <label class="tx-12">Position Size / Entry Price</label>
          <div :id="`p${algo_id}-position-div`">Loading..</div>

        </v-col>
<!--        <v-col>
          <label class="tx-12">Open Buy</label>
          <p>4.8372</p>
        </v-col>
        <v-col>
          <label class="tx-12">Open Sell</label>
          <p>8.2740</p>
        </v-col>-->
      </v-row>
<!--      <v-row>
        <v-col cols="12" class="text-subtitle-1 text-left mb-0">
          Max Risk<span class="float-right">
          <v-btn variant="tonal" size="small" class="ma-0 pa-0">
            <v-icon icon="mdi-content-save-outline" ></v-icon>
          </v-btn>
        </span>
        </v-col>
&lt;!&ndash;        <h6 class="slim-card-title tx-primary">Max Risk</h6>&ndash;&gt;
        <v-col
        cols="4" class="mt-0"
        >
&lt;!&ndash;          <label class="text-subtitle-2">Long</label><br>&ndash;&gt;
          <v-text-field
              v-model="max_long"
              model-value="1234"
              variant="outlined"
              label="Long"
              required
          ></v-text-field>
        </v-col>
        <v-col
            cols="4"
        >
&lt;!&ndash;          <label class="text-subtitle-2">Short</label><br>&ndash;&gt;
          <v-text-field
              v-model="max_short"
              model-value="1234"
              label="Short"
              variant="outlined"
              required
          ></v-text-field>
        </v-col>
        <v-col
            cols="4"
        >
&lt;!&ndash;          <label class="text-subtitle-2">Loss</label><br>&ndash;&gt;
          <v-text-field
              v-model="max_loss"
              model-value="1234"
              variant="outlined"
              label="Loss"
              required
          ></v-text-field>

        </v-col>
      </v-row>
      <v-row>
        <v-col
        cols="12">
          <p class="tx-12 mg-b-0">Round Trip Latency Average.</p>
          <v-progress-linear
              model-value="45"
              bg-color="success"
              color="green"
          ></v-progress-linear>
        </v-col>
      </v-row>-->
    </v-card-text>
    <v-card-actions class="float-right">
      <div v-if="scalper=true">
        <EditScalper :algo_id="algo_id"></EditScalper>
      </div>
      <v-btn v-else
          variant="outlined"
          color="teal-accent-4"
          @click="reveal = true"
          size="small"

      >
        <v-icon icon="mdi-cog-outline"></v-icon>

      </v-btn>
      <v-btn
          variant="outlined"
          color="red-accent-4"
          size="small"
          @click="confirmStop"

      >
        <v-icon icon="mdi-stop"></v-icon>
      </v-btn>
      <v-btn
          variant="outlined"
          color="green-accent-4"
          size="small"
          @click="confirmStart"

      >
        <v-icon icon="mdi-play"></v-icon>
      </v-btn>

<!--      <v-btn
          variant="outlined"
          color="blue-accent-4"
          size="small"
      >
        <v-icon icon="mdi-eye"></v-icon>
      </v-btn>-->
      <v-btn
          variant="outlined"
          color="red-accent-4"
          size="small"
          @click="confirmDelete"
      >
        <v-icon icon="mdi-delete-forever"></v-icon>
      </v-btn>
    </v-card-actions>

    <v-expand-transition>
      <v-card
          v-if="reveal"
          class="transition-fast-in-fast-out v-card--reveal h-100"
          style="height: 100%;"
      >


      </v-card>
    </v-expand-transition>
  </v-card>

</template>

<script>

import EditScalper from "@/components/forms/EditScalper.vue";
import GuardianProvider from "@/resources/guardian_provider";
import Pusher from 'pusher-js';
const guardian = new GuardianProvider();

export default {
  name: "AlgoBlock",
  components: {
    EditScalper
  },
  props: ['symbol_name', 'target_exchange', 'source_exchange', 'connection_name', 'exchange_name',
    "friendly_name", "bot_type", "algo_id" ],
  data() {

    let algoDetails = this.$alricDb.queryAll("algos", {query: {id: this.algo_id}})
    // console.log(algoDetails[0])
    const channel_prefix = algoDetails[0].service_name;
    const channelsProp = [channel_prefix+'_status', channel_prefix+'_position_size', channel_prefix+'_orders',
      channel_prefix+'_position', channel_prefix+'_error', channel_prefix+'_latency']

    return {
      reveal: false,
      max_long: null,
      max_short: null,
      max_loss: null,
      form_fields: null,
      scalper: false,
      serviceName: channel_prefix,
      algoDetails: algoDetails[0],
      socket: null,
      channelsProp,
      messages: channelsProp.reduce((acc, channel) => {
        acc[channel] = '';
        return acc;
      }, {}),
    };
  },
  /*data: () => ({
    reveal: false,
    max_long: null,
    max_short: null,
    max_loss: null,
    form_fields: null,
    scalper: false,
    socket: null,
    channels: ['channel1', 'channel2', 'channel3'],
    messages: {
      channel1: '',
      channel2: '',
      channel3: '',
    },

  }),*/
  /*created() {
    if( this.bot_type === "v2/scalper") {
      this.scalper = true;
    }
    else {
      this.scalper = false;
    }
  },*/
  methods: {
    confirmRestart() {
      this.$root.$confirm({
        title: "Restart Algo?",
        message: "This will stop and restart this bot",
        options: {
          btnDisagree: "No",
          btnAgree: "Yes",
        },
        agree: () => {
          guardian.controlAlgo(this.algo_id, 'restart').then(() => {})
        },
        disagree: () => {},
      });
    },
    confirmStop() {
      this.$root.$confirm({
        title: "Stop Bot?",
        message: "This will stop this bot",
        options: {
          btnDisagree: "No",
          btnAgree: "Yes",
        },
        agree: () => {
          guardian.controlAlgo(this.algo_id, 'stop').then(() => {})
        },
        disagree: () => {},
      });
    },
    confirmStart() {
      this.$root.$confirm({
        title: "Start Bot?",
        message: "This will start this bot",
        options: {
          btnDisagree: "No",
          btnAgree: "Yes",
        },
        agree: () => {
          guardian.controlAlgo(this.algo_id, 'start').then(() => {})
        },
        disagree: () => {},
      });
    },
    confirmDelete() {
      this.$root.$confirm({
        title: "Delete Bot?",
        message: "This will delete the bot and cannot be recovered",
        options: {
          btnDisagree: "No",
          btnAgree: "Yes",
        },
        agree: () => {
          guardian.deleteAlgo(this.algo_id).then(() => {
            this.$emit('algo-deleted', this.algo_id);
          })
        },
        disagree: () => {},
      });
    },
    handleMessage(event) {
      this.message = event.data;
      console.log(event.data)
    }
  },
  mounted() {
    this.scalper = this.bot_type === "v2/scalper";
    let pusher = new Pusher('alric-key', {
      wsHost: 'ws.alric.io',
      wsPort: 443,
      forceTLS: true,
      encrypted: true,
      disableStats: true,
      enabledTransports: ['ws','wss'],
      cluster: "eu"
    });

    // Subscribe to channels
    this.channelsProp.forEach((channelName) => {
      const channel = pusher.subscribe(channelName);
      let divId = `#${channelName.split('_')[1].replace('_', '-')}-div`;
      divId = divId.replace('#', 'p'+this.algo_id+'-')
      // console.log(divId)
      const div = document.getElementById(divId);
      // console.log(div)
      channel.bind('client-update', (data) => {

        if( undefined !== data.message.position_size ){
          // console.log(data.message.position_size)
          div.textContent = '.....'
          div.textContent = '...'
          div.textContent = (data.message.position_size.toLocaleString() ) + ' / '+data.message.entry_price.toFixed(this.algoDetails.algo_config.pricePrecision);
        }

      });
    });

  },
  beforeUnmount() {
    // this.socket.close();
  },
}
</script>

<style scoped>
.v-card--reveal {
  bottom: 0;
  opacity: 1 !important;
  position: absolute;
  width: 100%;
}
</style>
