<template>
  <div class="container">
    <h1>Ladder Order Bot</h1>
    <form>
      <div class="form-group">
        <label>Size in USD</label>
        <input type="number" v-model="size" class="form-control" />
      </div>

      <div class="form-group">
        <label>Between Prices</label>
        <div class="row">
          <div class="col">
            <input type="number" v-model="betweenPrice1" class="form-control" />
          </div>
          <div class="col">
            <input type="number" v-model="betweenPrice2" class="form-control" />
          </div>
        </div>
      </div>

      <div class="form-group">
        <label>Total Orders</label>
        <input type="range" v-model="totalOrders" min="1" max="100" step="1" class="form-control-range" />
        <span>{{ totalOrders }}</span>
      </div>

      <div class="form-group">
        <label>Price Distribution</label>
        <input type="range" v-model="priceDistribution" min="1" max="100" step="1" class="form-control-range" />
        <span>{{ priceDistribution }}</span>
      </div>

      <div class="form-group">
        <label>Skew Distribution</label>
        <input type="range" v-model="skewDistribution" min="0" max="100" step="1" class="form-control-range" v-if="cubicSelected"/>
        <span v-if="cubicSelected">{{ skewDistribution }}</span>
      </div>

      <div class="form-group">
        <label>Use Cubic or Exponential Distribution</label>
        <div class="form-check">
          <input type="radio" v-model="distributionType" value="cubic" class="form-check-input" />
          <label class="form-check-label">Cubic</label>
        </div>
        <div class="form-check">
          <input type="radio" v-model="distributionType" value="exponential" class="form-check-input" />
          <label class="form-check-label">Exponential</label>
        </div>
      </div>

      <div class="form-group">
        <label>Average Entry Price:</label>
        <div class="row">
          <div class="col">
            <h4>{{ averageEntryPrice }}</h4>
          </div>
        </div>
      </div>

      <button type="button" class="btn btn-primary" @click="generateOrders">Generate Orders</button>
    </form>
  </div>
</template>





<script>
// import axios from 'axios'

export default {
  name: 'LadderForm',
  data() {
    return {
      size: 0,
      betweenPrice1: 0,
      betweenPrice2: 0,
      totalOrders: 10,
      priceDistribution: 'even',
      skewDistribution: 0,
      cubicExpo: 'cubic',
      averageEntryPrice: 0,
      skewDistributionRange: [0, 33],
      maxTotalOrders: 50,
      minTotalOrders: 5,
      cubicSelected: true,
      distributionType: true,
    }
  },
  methods: {
    calculateAverageEntryPrice() {
      const betweenPriceDiff = Math.abs(this.betweenPrice1 - this.betweenPrice2)
      const priceIncrement = betweenPriceDiff / (this.totalOrders - 1)
      const totalAmount = parseFloat(this.size)
      let totalAmountDistributed = 0
      let runningPriceTotal = 0
      for (let i = 0; i < this.totalOrders; i++) {
        let orderPrice = 0
        if (this.priceDistribution === 'even') {
          orderPrice = this.betweenPrice1 + i * priceIncrement
        } else if (this.cubicExpo === 'cubic') {
          const t = i / (this.totalOrders - 1)
          const cubic = 3 * Math.pow(t, 2) - 2 * Math.pow(t, 3)
          const skewAmount = (this.skewDistribution / 100) * betweenPriceDiff
          const skew = (cubic - 0.5) * skewAmount
          orderPrice = this.betweenPrice1 + i * priceIncrement + skew
        } else {
          const t = i / (this.totalOrders - 1)
          const expo = Math.pow(t, 2)
          const skewAmount = (this.skewDistribution / 100) * betweenPriceDiff
          const skew = (expo - 0.5) * skewAmount
          orderPrice = this.betweenPrice1 + i * priceIncrement + skew
        }
        const orderSize = totalAmount / this.totalOrders
        const orderTotal = orderPrice * orderSize
        totalAmountDistributed += orderSize
        runningPriceTotal += orderTotal
      }
      this.averageEntryPrice = runningPriceTotal / totalAmountDistributed
    }
  },
  watch: {
    size() {
      this.calculateAverageEntryPrice()
    },
    betweenPrice1() {
      this.calculateAverageEntryPrice()
    },
    betweenPrice2() {
      this.calculateAverageEntryPrice()
    },
    totalOrders() {
      this.calculateAverageEntryPrice()
    },
    priceDistribution() {
      this.calculateAverageEntryPrice()
    },
    cubicExpo() {
      this.calculateAverageEntryPrice()
    },
    skewDistribution() {
      this.calculateAverageEntryPrice()
    }
  },
  computed: {
    skewDistributionMin() {
      return 0
    },
    skewDistributionMax() {
      return this.cubicExpo === 'cubic' ? 33 : 50
    },
    skewDistributionStep() {
      return 1
    },
    totalOrdersMin() {
      return this.minTotalOrders
    },
    totalOrdersMax() {
      return this.maxTotalOrders
    },
    totalOrdersStep() {
      return 1
    }
  }
}
</script>




<style scoped>

</style>
