<template>

    <v-col cols="3">
      <v-card loading><!-- @todo: How is loading set dynamically? @Game : this called component dynamically already -->
        <v-card-text>
          <v-card-title>{{connection_name}}</v-card-title>
          <v-card-subtitle>{{ exchange }} : {{ type }}</v-card-subtitle>

            <v-row class="mt-0">

              <v-col>

                  <v-btn
                      class="ma-2"
                      @click="dialog = true"
                  >
                    {{ symbols }} Symbols
                    <v-icon size="small"
                            end
                            icon="mdi-pencil"
                    ></v-icon>

                  </v-btn>
                <div class="text-center w-20">
                  <v-dialog
                      v-model="dialog"
                      max-width="50%"
                  >
                    <v-card>
                      <v-form @submit.prevent="onSubmit" >
                        <v-card-text class="text-center ">
                          {{ connection_name }} Symbols:
                          <v-combobox
                              v-model="form.selected_symbols"
                              :items="getItems"
                              item-value="symbol_id"
                              item-title="symbol_name"
                              label="Active Symbols"
                              multiple
                              outlined
                              dense
                          ></v-combobox>
                        </v-card-text>
                        <v-card-actions>
                          <v-field type="hidden" name="connection_id" :model-value="form.connection_id"></v-field>
                          <v-btn color="primary" block type="submit">Save</v-btn><!-- @todo: Update to save select items method -->
                          <v-btn color="primary" block @click="dialog = false">Cancel</v-btn>
                        </v-card-actions>
                      </v-form>

                    </v-card>
                  </v-dialog>
                </div>
<!--                <v-btn
                    class="ma-2"
                >
                  {{ symbols }} Symbols
                  <v-icon size="small"
                      end
                      icon="mdi-pencil"
                  ></v-icon>

                </v-btn>-->

              </v-col>
            </v-row>

        </v-card-text>
        <v-card-actions>
          <v-row>
            <v-col>
              <v-item-group class="d-inline-flex mx-auto">
                <v-item>
                  <v-btn size="medium" flat color="secondary" @click="confirmRestart">
                    <v-icon icon="mdi-restart" color="secondary"></v-icon>
                  </v-btn>
                </v-item>

                <v-item>
                  <v-btn size="medium" flat color="primary" @click="confirmStop">
                    <v-icon icon="mdi-stop" color="primary"></v-icon>
                  </v-btn>
                </v-item>

                <v-item>
                  <v-btn size="medium" flat color="danger" @click="confirmDelete">
                    <v-icon icon="mdi-delete-forever" color="danger"></v-icon>
                  </v-btn>
                </v-item>
              </v-item-group>
            </v-col>
          </v-row>


        </v-card-actions>
        <v-row class="text-subtitle-1 font-weight-thin" >
          <v-col>
            Active Since:
          </v-col>
          <v-col>
            {{ active_since }}
          </v-col>
        </v-row>
      </v-card>
    </v-col>



</template>

<script>
import GuardianProvider from "@/resources/guardian_provider";
// import localStorageDB from "localstoragedb";

const guardian = new GuardianProvider();

export default {
  name: "ConnectionBlock",
  props: ['connection_id','exchange_id', 'connection_name', 'exchange', 'type', 'symbols', 'active_since'],
  data() {
    return {
      // alricDb: this.$alricDb,
      loading: false,
      dialog: false,
      form: {
        connection_id: this.connection_id,
        selected_symbols:  [],
      }
    }
  },
  computed:{
    getItems(){
      console.log(this.connection_id)
      // @todo @Game: This should show all exchange symbols - with the ones inside the 'connection_symbols' table selected. // Game : DONE
      let symbol_list = this.$alricDb.queryAll("symbols", {query: {exchange_id: this.exchange_id}})

      // GET SYMBOL LIST FROM EXCHANGE ID AND ALSO CHECK THE CONNECTION SYMBOL LIST IF EXIST OR NOT
      this.$alricDb.queryAll("connection_symbols", {query: {connection_id: this.connection_id}}).forEach(function(value, key) {
        let index = symbol_list.findIndex(x => x.symbol_id === value.symbol_id);

        index === -1 ? symbol_list.push({value}) : console.log("object already exists")
      })

      // console.log('symbol_list',symbol_list)

      return symbol_list

    },  },
  methods: {
    confirm() { // @note: https://www.codenotfound.dev/article/setup-vue-sweetalert2-as-a-global-helper-in-vue-2
      this.$root.$confirm({
        title: "Confirm",
        message: "Confirm message",
        options: {
          btnDisagree: "No",
          btnAgree: "Yes",
        },
        agree: this.agree,
        disagree: this.disagree,
      });
    },
    agree() {
      console.log("agree!");
    },
    disagree() {
      console.log("disagree!");
    },
    confirmRestart() {
      this.$root.$confirm({
        title: "Restart Connection?",
        message: "This will also restart all systems using this connection",
        options: {
          btnDisagree: "No",
          btnAgree: "Yes",
        },
        agree: () => this.agreeParams("passed 1", "passed 2"),
        disagree: () => {
          console.log("disagree callback");
        },
      });
    },
    confirmStop() {
      this.$root.$confirm({
        title: "Stop Connection?",
        message: "This will also STOP all systems using this connection",
        options: {
          btnDisagree: "No",
          btnAgree: "Yes",
        },
        agree: () => this.agreeParams("passed 1", "passed 2"),
        disagree: () => {
          console.log("disagree callback");
        },
      });
    },
    reRender(){
      this.$store.dispatch("setAvailableConnections", this.$alricDb).then(() => {
        window.location.reload();
      })
    },
    confirmDelete() {
      this.$root.$confirm({
        title: "Delete Connection?",
        message: "This will permanently remove this connection",
        options: {
          btnDisagree: "No",
          btnAgree: "Yes",
        },
        agree: () => {
          guardian.deleteConnection(this.connection_id).then(() => {
            this.$emit('connection-deleted', this.connection_id);
          })
              // @todo @Game - Need to remove (self) this block after connection is deleted. // GAME Fixed
        },
        disagree: () => {
          console.log("disagree callback");
        },
      });
    },
    agreeParams(param1, param2) {
      console.log(`param 1: ${param1}, param 2: ${param2}`);
    },
    closeForm(){
      this.dialog_new = false;
    },
    async onSubmit() {
      /*let choices = this.form.selected_symbols;
      let selectedIds = []
      let i = 0;
      do {
        selectedIds.push(choices[i].id)
        i++
      } while (i < choices.length)*/
      console.log(JSON.stringify(this.form.selected_symbols))
      // @todo Daniel/Game this needs to set the selected symbols to active = 1 not delete them
      // await guardian.saveConnectionSymbols(this.form.connection_id,JSON.stringify(this.form.selected_symbols))
      this.dialog_new = false; // @todo @Game This should be closing the dialog window, but it does not.
    },
  },
}
</script>

<style scoped>

</style>
