<template>
  <v-dialog v-model="dialog_new" persistent max-width="60%">
    <template v-slot:activator="{ props }">
      <v-btn class="ma-2" v-bind="props">
        <v-icon start icon="mdi-plus-circle"></v-icon>
        New MM v2
      </v-btn>
    </template>
    <v-card>
      <v-form v-on:submit.prevent="submitForm">
        <input type="hidden" value="v2/market-maker-one" name="options['bot_type']">
        <v-card-title>
          <span class="text-h5">New MM (v2) (Scalper)</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="6" md="4">
                <v-text-field
                  v-model="form.name"
                  name="name"
                  variant="outlined"
                  density="compact"
                  label="Friendly Name *"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <!-- @todo: this list should come from the API/localStorage -->
                <v-autocomplete
                  v-model="form.connection_id"
                  name="connection_id"
                  variant="outlined"
                  density="compact"
                  :items="connections"
                  item-title="name"
                  item-value="id"
                  label="Connection *"
                  @update:modelValue="selectConnection"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-select
                  v-model="form.symbol"
                  name="options['symbol_id']"
                  :items="symbols"
                  item-title="symbol_name"
                  item-value="symbol_id"
                  variant="outlined"
                  density="compact"
                  label="Symbol *"
                  required
                ></v-select>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-select
                  v-model="form.options['orderType']"
                  name="options['orderType']"
                  :items="['Both', 'Buy', 'Sell']"
                  variant="outlined"
                  density="compact"
                  label="Type *"
                  @update:modelValue="selectOrderType"
                  required
                ></v-select>
              </v-col>
              <v-col cols="12" sm="3" md="3">
                <v-text-field
                  v-model="form.options['timeframe']"
                  name="options['timeframe']"
                  label="Timeframe *"
                  hint="Set in seconds"
                  variant="outlined"
                  density="compact"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
            <!-- START BUY ROW -->
            <v-row id="buysRow" v-if="isOrderTypeBuyShow">
              <div class="text-muted w-100">Buy Details</div>
              <br />
              <v-col cols="12" sm="3" md="3">
                <v-text-field
                  v-model="form.options['openBuyMaxDistance']"
                  name="options['openBuyMaxDistance']"
                  label="Open Buy Max Distance *"
                  variant="outlined"
                  density="compact"
                  type="text"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="3" md="3">
                <v-text-field
                  v-model="form.options['openBuyQuantity']"
                  name="options['openBuyQuantity']"
                  label="Open Buy Qty *"
                  variant="outlined"
                  density="compact"
                  type="text"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="3" md="3">
                <v-text-field
                  v-model="form.options['closeBuyMaxDistance']"
                  name="options['closeBuyMaxDistance']"
                  label="Close Buy Max Distance *"
                  variant="outlined"
                  density="compact"
                  type="text"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="3" md="3">
                <v-text-field
                  v-model="form.options['closeBuyProfitQuantity']"
                  name="options['closeBuyProfitQuantity']"
                  label="Close Buy Profit Qty *"
                  variant="outlined"
                  density="compact"
                  type="text"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="3" md="3">
                <v-text-field
                  v-model="form.options['closeBuyLossQuantity']"
                  name="options['closeBuyLossQuantity']"
                  label="Close Buy Loss Qty *"
                  variant="outlined"
                  density="compact"
                  type="text"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
            <!-- END BUY ROW -->

            <!-- START SELL ROW -->
            <v-row id="sellsRow" v-if="isOrderTypeSellShow">
              <div class="text-muted w-100">Sell Details</div>
              <br />
              <v-col cols="12" sm="3" md="3">
                <v-text-field
                  v-model="form.options['openSellMaxDistance']"
                  name="options['openSellMaxDistance']"
                  label="Open Sell Max Distance *"
                  variant="outlined"
                  density="compact"
                  type="text"
                  required
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="3" md="3">
                <v-text-field
                  v-model="form.options['openSellQuantity']"
                  name="options['openSellQuantity']"
                  label="Open Sell Qty*"
                  variant="outlined"
                  density="compact"
                  type="text"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="3" md="3">
                <v-text-field
                  v-model="form.options['closeSellMaxDistance']"
                  name="options['closeSellMaxDistance']"
                  label="Close Sell Max Distance *"
                  variant="outlined"
                  density="compact"
                  type="text"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="3" md="3">
                <v-text-field
                  v-model="form.options['closeSellProfitQuantity']"
                  name="options['closeSellProfitQuantity']"
                  label="Close Sell Profit Qty *"
                  variant="outlined"
                  density="compact"
                  type="text"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="3" md="3">
                <v-text-field
                  v-model="form.options['closeSellLossQuantity']"
                  name="options['closeSellLossQuantity']"
                  label="Close Sell Loss Qty *"
                  variant="outlined"
                  density="compact"
                  type="text"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
            <!-- END SELL ROW -->
            <v-row>
              <v-col cols="12" sm="3" md="3">
                <v-text-field
                  v-model="form.options['maxQuantity']"
                  name="options['maxQuantity']"
                  label="Max Qty*"
                  variant="outlined"
                  density="compact"
                  type="text"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="3" md="3">
                <v-text-field
                  v-model="form.options['takeProfit']"
                  name="options['takeProfit']"
                  label="Take Profit*"
                  variant="outlined"
                  density="compact"
                  type="text"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="3" md="3">
                <v-text-field
                  v-model="form.options['stopLoss']"
                  name="options['stopLoss']"
                  label="Stop Loss*"
                  variant="outlined"
                  density="compact"
                  type="text"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="3" md="3">
                <v-text-field
                  v-model="form.options['maxSpread']"
                  name="options['maxSpread']"
                  label="Max Spread*"
                  variant="outlined"
                  density="compact"
                  type="text"
                  required
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="3" md="3">
                <v-select
                  v-model="form.options['spreadType']"
                  name="options['spreadType']"
                  :items="['Percentage', 'Currency']"
                  variant="outlined"
                  density="compact"
                  label="Spread Type *"
                  required
                ></v-select>
              </v-col>
            </v-row>
          </v-container>
          <small>*indicates required field</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue-darken-1"
            variant="text"
            @click="dialog_new = false"
          >
            Close
          </v-btn>
          <v-btn color="blue-darken-1" variant="text" @click="submitForm">
            Save
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import GuardianProvider from '@/resources/guardian_provider'
import {mapGetters} from "vuex";
import localStorageDB from "localstoragedb";

import('@/resources/guardian_provider')
const guardian = new GuardianProvider()

export default {
  name: 'NewMarkerMaker',
  data() {
    return {
      dialog_new: false,
      form: {
        name: '',
        options: {
          "exchange_id": "lmex", // @todo get this value from the connection.exchange
          "symbol": null,
          "orderType": "Both",
          "timeframe": 1,
          "openBuyMaxDistance": 0,
          "openBuyQuantity": 10,
          "closeBuyMaxDistance": 10,
          "closeBuyProfitQuantity": 10,
          "closeBuyLossQuantity": 10,
          "openSellMaxDistance": 0,
          "openSellQuantity": 0,
          "closeSellMaxDistance": 0,
          "closeSellProfitQuantity": 0,
          "closeSellLossQuantity": 0,
          "maxQuantity" : 10000,
          "takeProfit": 10,
          "stopLoss": 10000,
          "spreadType": "Currency",
          "maxSpread": 1,
          "botIdentifier": "MMBot_",
        },
        symbol: '',
        connection_id: '',
        botIdentifier: 'name'
      },
      isOrderTypeSellShow: true,
      isOrderTypeBuyShow: true,
      connections: JSON.parse(localStorage.getItem('available_connections')),
      symbols: [{ symbol_id: 1, symbol_name: 'BTC/USDT' }, { symbol_id: 2, symbol_name: 'ETH/USDT' }],
      // alricDb: this.$alricDb
    }
  },
  methods: {
    closeForm() {
      this.dialog_new = false
    },
    submitForm() {
      // @todo: Make sure the form.name does not already exist in the connections list (localStorage)
      guardian.saveMarketMaker('algos', this.form)
      this.closeForm()
      this.$emit('scalper-created');
      // @todo Refresh the blocks to show the new connection / OR.. Append the ConnectionBlock to the end of the group // GAME : DONE
    },
    selectOrderType(orderType) {
      if (orderType === 'Buy') {
        this.isOrderTypeBuyShow = true
        this.isOrderTypeSellShow = false
      } else if (orderType === 'Sell') {
        this.isOrderTypeBuyShow = false
        this.isOrderTypeSellShow = true
      } else {
        this.isOrderTypeBuyShow = true
        this.isOrderTypeSellShow = true
      }
    },

    /**
     * When the user selects the connection we need related information
     * @param connectionId
     * @returns {Promise<void>}
     */
    async selectConnection(connectionId) {

      let selectedConnection = this.$alricDb.queryAll("connections",{
        query: {id: connectionId}
      })[0];

      // console.log('selectedConnection', selectedConnection)
      this.form.options.exchange_id = selectedConnection.exchange_id;
      this.form.connection_id = selectedConnection.id;
      this.symbols = this.$alricDb.queryAll("connection_symbols", {query: {connection_id: selectedConnection.id}}); //JSON.stringify(tmpSymbols); //'['+tmpSymbols+']';
      // console.log('HERE',JSON.stringify(tmpSymbols))
    }
  },
  computed: {
    ...mapGetters([
      'getExchangeFromConnection','getConnectionFromId'
      // ...
    ])
  }
}
</script>

<style scoped></style>
