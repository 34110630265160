<template>
    <v-app>
      <NavBar :page_name="page_name"></NavBar>
      <v-app-bar compact class="" >

        <v-container class="m-0 p-0 mx-3 mt-3"  >
          <v-row no-gutters>
              <v-col class="align-content-start" cols="3">
                <v-select
                  class="mb-2"
                  v-model="getActiveExchange"
                  :items="getConnections"
                  item-title="name"
                  item-value="id"
                  density="compact"
                  variant="plain"
                  @update:modelValue="setActiveExchange"
                >
                </v-select>
              </v-col>
              <v-col class="align-content-start" cols="3">
                <v-select
                    id="selected_connection"
                  class="mb-2 align-top"
                  v-model="getActiveSymbol"
                  :items="getExchangeTicker"
                  item-title="state"
                  item-value="abbr"
                  density="compact"
                  variant="plain"
                  :menu-props="{ closeOnClick: false, closeOnContentClick: true }"
                >
                  <template v-slot:selection="{ item }">
                    <v-avatar size="25px">
                      <v-img :src="getSymbolImgUrl(item.title)"></v-img> </v-avatar
                    ><span class="pl-2">{{ item.title }}</span>
                  </template>
                  <template v-slot:prepend-item>
                    <v-row class="option-header">
                      <v-col>
                        <v-sheet class="pa-2 ma-2">Symbol</v-sheet>
                      </v-col>
                      <v-col>
                        <v-sheet class="pa-2 ma-2">Price</v-sheet>
                      </v-col>
                      <v-col>
                        <v-sheet class="pa-2 ma-2">24H change %</v-sheet>
                      </v-col>
                      <v-col>
                        <v-sheet class="pa-2 ma-2">24H volume</v-sheet>
                      </v-col>
                    </v-row>
                    <v-divider class="mt-2"></v-divider>
                  </template>
                  <template v-slot:item="{ item }">
                    <v-list-item
                      :value="item.value"
                      width="700"
                      @click="setActivePair(item.value.symbol)"
                    >
                      <v-row no-gutters class="option">
                        <v-col align-self="center">
                          <v-sheet class="pa-2 ma-2">
                            <v-avatar size="25px">
                              <v-img
                                :src="getSymbolImgUrl(item.value.symbol)"
                              ></v-img> </v-avatar
                            ><span class="pl-2">{{ item.value.symbol }}</span>
                          </v-sheet>
                        </v-col>
                        <v-col>
                          <v-sheet class="pa-2 ma-2">{{ item.value.price }}</v-sheet>
                        </v-col>
                        <v-col>
                          <v-sheet
                            class="pa-2 ma-2"
                            :class="[
                              item.value.daypercentchange[0] == '-'
                                ? 'text-red'
                                : 'text-green'
                            ]"
                            >{{ item.value.daypercentchange }}</v-sheet
                          >
                        </v-col>
                        <v-col>
                          <v-sheet class="pa-2 ma-2">{{
                            item.value.dayvolumechange
                          }}</v-sheet>
                        </v-col>
                      </v-row>
                    </v-list-item>
                  </template>
                </v-select>
              </v-col>
<!--              <v-col class="align-content-center" cols="3">
                <v-chip-group
                  v-model="getActiveSymbol"
                  selected-class="text-deep-purple-accent-4"
                  mandatory
                  class="d-flex"
                >
                  <v-chip
                    v-for="piar in quickPiars"
                    size="small"
                    :key="piar"
                    :value="piar"
                    @click="setActivePair(piar)"
                  >
                    {{ piar }}
                  </v-chip>
                  &lt;!&ndash; <v-chip size="small">ETH/USDT</v-chip>
                  <v-chip size="small">BTC/USDT</v-chip>
                  <v-chip size="small">XRP/USDT</v-chip>
                  <v-chip size="small">CAD/USDT</v-chip> &ndash;&gt;
                </v-chip-group>
              </v-col>-->
            <v-col cols="3">
              &nbsp;
            </v-col>
          </v-row>
        </v-container>

      </v-app-bar>
      <v-main>
       <TradingDashboard/>
      </v-main>
    </v-app>
</template>
<script>

import TradingDashboard from '../components/TradingDashoard.vue'
import NavBar from '../components/NavBar.vue'
import {mapGetters} from "vuex";

  export default {
    components: {
      TradingDashboard, NavBar
    },
    beforeMount() {

    },
    beforeCreate() {
      this.$store.dispatch("setAvailableConnections", this.$alricDb);
      this.$store.dispatch("setAvailableExchanges", this.$alricDb);
      this.$store.dispatch("setAvailableAlgos", this.$alricDb);
    },
    data: () => ({
      page_name: 'Dashboard',
    }),
    watch: {
      group () {
        // this.drawer = false
      },
    },
    methods: {
      setActiveExchange(connectionId) {
        let selectedConnection = this.$alricDb.queryAll("connections", { query: {id: connectionId}})[0]
        let selectedExchange = this.$alricDb.queryAll("exchanges", { query: {id: selectedConnection.exchange_id}})[0]

        console.log("selectedExchange",selectedExchange);

        this.$store.commit('SET_TRADING_EXCHANGE', selectedExchange.name.toUpperCase())
        this.$store.commit('SET_TRADING_CONNECTION', connectionId)

        this.$store.commit('SET_TRADING_SYMBOL', 'BTC/USDT')
                // console.log('XXXXXX',connectionId);

        this.$store.dispatch('setExchangeTicker', { db : this.$alricDb, connectionId : connectionId }) // @todo: @Daniel Feb-23  @Game: Please restore I made a mess! // GAME : Change to use availabe symbol for these ccxt query

      },
      setActivePair(symbol) {
        // console.log('selected symbol : ', symbol)
        this.$store.commit('SET_TRADING_SYMBOL', symbol)
      },
      getExchangeImgUrl() {
        if (this.$store.state.connections) {
          let selectedExchangeData = this.$store.state.connections.find(
              (item) => item.exchange == this.selectedConnection
          )
          return selectedExchangeData.logo
        }
      },
      getSymbolImgUrl(symbol) {
        return (
            'https://assets.coincap.io/assets/icons/' +
            symbol.split('/')[0].toLowerCase() +
            '@2x.png'
        )
      },
      getPercentChange(percentChange) {
        if (percentChange[0] == '-') return
      }
    },
    mounted() {

      let cxn = this.$store.state.trading_symbol.connection
      if( undefined ===  cxn || cxn === null || cxn === '' ) {
        console.log('CXN-ERROR', 'Connection Not Defined')
      }
      else {
        // cxn = this.$store.state.trading_symbol.connection
        console.log(this.$store.state.trading_symbol.connection, 'DASH-CXN')
        let cxnID = cxn.id
        this.$store.dispatch('setExchangeTicker',cxnID)
      }

      this.activeExchange = this.$store.state.trading_symbol.exchange

    },
    computed: {
      ...mapGetters([
        'getExchangeFromConnection','getConnectionFromId'
        // ...
      ]),
      getConnections() {
        return this.$store.state.connections;
      },
      getExchangeTicker() {
        return this.$store.state.tickers
      },
      getActiveExchange() {
        return this.$store.state.trading_symbol.exchange
      },
      getActiveConnection() {
        return this.$store.state.trading_symbol.connection
      },
      getActiveSymbol() {
        return this.$store.state.trading_symbol.symbol
      }

    }
  }
</script>
<style scoped>

</style>
