import { createApp } from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'
import localstoragedb from './plugins/localstoragedb'

import router from './router'
import store from './store'

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";
import "@/assets/css/ionicons.min.css";
import "@/assets/scss/style.scss";

loadFonts()

createApp(App).use(store).use(router)
  .use(vuetify).use(localstoragedb)
  .mount('#app')
