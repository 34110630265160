import axios from 'axios'

class HttpRequest {

  constructor (url = 'https://app.alric.io/api/v1') {
    // this.axios = axios
    this.axiosInstance = axios.create({
      baseURL: url,
      timeout: 120000
    })

    /*this.axiosInstance.interceptors.request.use(function (config) {
      // Do something before request is sent
      return config
    }, function (error) {
      // Do something with request error
      return Promise.reject(error)
    })

    // Add a response interceptor
    this.axiosInstance.interceptors.response.use(function (response) {
      // Do something with response data
      return response
    }, function (error) {
      // Do something with response error
      return Promise.reject(error)
    })*/
  }

  setHeader () {
    // this.axiosInstance.defaults.headers.common[header.key] = header.value
    // this.axiosInstance.defaults.headers.common = header // @todo: DS: unsure if this is used
    this.axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${sessionStorage.getItem('access_token')}`; // @todo: Required for Laravel
    this.axiosInstance.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded'

  }

  get (methodName, data) {
    this.setHeader()
    return this.axiosInstance.get(methodName, {
      params: data
    })
  }

  create (methodName, data) {
    this.setHeader()
    return this.axiosInstance.post(methodName, data)
  }

  update (methodName, data) {
    this.setHeader()
    return this.axiosInstance.put(methodName, data)
  }

  delete (methodName, param, data) {
    this.setHeader()
    return this.axiosInstance.delete(methodName, {
      params: param,
      data: data
    })
  }

  request (type, url, data) {
    axios.defaults.baseURL = 'https://app.alric.io/api';
    axios.defaults.headers.common['Authorization'] = `Bearer ${sessionStorage.getItem('access_token')}`;
    axios.defaults.headers.post['Authorization'] = `Bearer ${sessionStorage.getItem('access_token')}`;
    axios.defaults.headers.post['Content-Type'] = 'application/json';
    axios.defaults.headers.post['Accept'] = 'application/json';
    axios.defaults.headers.patch['Content-Type'] = 'application/json';


    let promise = null
    switch (type) {
      case 'GET': promise = axios.get(url, { params: data }); break
      case 'POST': promise = axios.post(url, data,{maxBodyLength: Infinity}); break
      case 'LOGIN': promise = axios.post(url, data); break
      case 'PATCH': promise = axios.patch(url, data); break
      case 'PUT': promise = axios.put(url, data); break
      case 'DELETE': promise = axios.delete(url, data); break
      default : promise = axios.get(url, { params: data }); break
    }
    return promise
  }

}

export default HttpRequest
