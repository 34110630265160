<template>
  <v-dialog v-model="dialog_new" persistent max-width="60%">
    <template v-slot:activator="{ props }">
      <v-btn class="ma-2" v-bind="props">
        <v-icon start icon="mdi-plus-circle"></v-icon>
        New Scalper
      </v-btn>
    </template>
    <v-card>
      <v-form v-on:submit.prevent="submitForm">
        <input type="hidden" value="v2/scalper" name="options['bot_type']">
        <v-card-title>
          <span class="text-h5">New Scalper Bot</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="6" md="4">
                <v-text-field
                  v-model="form.name"
                  name="name"
                  variant="outlined"
                  density="compact"
                  label="Friendly Name *"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-autocomplete
                  v-model="form.connection_id"
                  name="connection_id"
                  variant="outlined"
                  density="compact"
                  :items="connections"
                  item-title="name"
                  item-value="id"
                  label="Connection *"
                  @update:modelValue="selectConnection"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-select
                  v-model="form.symbol"
                  name="options['symbol_id']"
                  :items="symbols"
                  item-title="symbol_name"
                  item-value="symbol_id"
                  variant="outlined"
                  density="compact"
                  label="Symbol *"
                  required
                ></v-select>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-select
                    v-model="form.options['botSide']"
                    name="options['botSide']"
                    :items="['Buy', 'Sell']"
                    variant="outlined"
                    density="compact"
                    label="Type *"
                    required
                ></v-select>
              </v-col>
              <v-col cols="12" sm="3" md="4">
                <v-text-field
                    v-model="form.options['orderTimeMax']"
                    name="options['orderTimeMax']"
                    label="Timeframe *"
                    hint="On order book in seconds"
                    variant="outlined"
                    density="compact"
                    required
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="3" md="4">
                <v-text-field
                    v-model="form.options['pricePrecision']"
                    name="options['pricePrecision']"
                    label="Price Precision *"
                    hint="Number of decimal places"
                    variant="outlined"
                    density="compact"
                    type="text"
                    required
                ></v-text-field>
              </v-col>
            </v-row>
            <!-- START BUY ROW -->
            <v-row id="buysRow">
              <div class="text-muted w-100">Details</div>
              <br />
              <v-col cols="12" sm="4" md="4">
                <v-text-field
                    v-model="form.options['orderSize']"
                    name="options['orderSize']"
                    label="Order Size *"
                    hint="Only used if Ranges are BOTH 0"
                    variant="outlined"
                    density="compact"
                    type="text"
                    required
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="4" md="4">
                <v-text-field
                    v-model="form.options['rangeStart']"
                    name="options['rangeStart']"
                    label="Qty Range From *"
                    hint="Lowest Qty Order (set to 0 to use OrderSize)"
                    variant="outlined"
                    density="compact"
                    type="text"
                    required
                    @update:modelValue="rangeStartRangeEndcheck"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="4" md="4">
                <v-text-field
                    v-model="form.options['rangeEnd']"
                    name="options['rangeEnd']"
                    label="Qty Range To *"
                    hint="Highest Qty (set to 0 to use OrderSize)"
                    variant="outlined"
                    density="compact"
                    type="text"
                    required
                    @update:modelValue="rangeStartRangeEndcheck"
                ></v-text-field>
              </v-col>

            </v-row>


            <v-row>
              <v-col cols="12" sm="3" md="4">
                <v-text-field
                    v-model="form.options['maxPositionSize']"
                    name="options['maxPositionSize']"
                    label="Max Position Size *"
                    variant="outlined"
                    density="compact"
                    type="text"
                    required
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="3" md="4">
                <v-text-field
                    v-model="form.options['takeProfit']"
                    name="options['takeProfit']"
                    label="Take Profit*"
                    variant="outlined"
                    density="compact"
                    type="text"
                    required
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="3" md="4">
                <v-text-field
                    v-model="form.options['stopLoss']"
                    name="options['stopLoss']"
                    label="Stop Loss*"
                    hint="Still not tested"
                    variant="outlined"
                    density="compact"
                    type="text"
                    required
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="3" md="4">
                <v-select
                    v-model="form.options['spreadType']"
                    name="options['spreadType']"
                    :items="['Currency']"
                    variant="outlined"
                    density="compact"
                    label="Spread Type *"
                    required
                ></v-select>
              </v-col>
              <v-col cols="12" sm="3" md="4">
                <v-text-field
                    v-model="form.options['buySpread']"
                    name="options['buySpread']"
                    label="Buy Spread*"
                    variant="outlined"
                    density="compact"
                    type="text"
                    required
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="3" md="4">
                <v-text-field
                    v-model="form.options['sellSpread']"
                    name="options['sellSpread']"
                    label="Sell Spread *"
                    variant="outlined"
                    density="compact"
                    type="text"
                    required
                ></v-text-field>
              </v-col>


            </v-row>
          </v-container>
          <small>*indicates required field</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="blue-darken-1"
              variant="text"
              @click="dialog_new = false"
          >
            Close
          </v-btn>
          <v-btn color="blue-darken-1" variant="text" @click="submitForm">
            Save
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import GuardianProvider from '@/resources/guardian_provider'
import {mapGetters} from "vuex";

import('@/resources/guardian_provider')
const guardian = new GuardianProvider()

export default {
  name: 'NewScalper',
  data() {
    return {
      dialog_new: false,
      form: {
        name: '',
        options: {
          "exchange_id": "lmex", // @todo get this value from the connection.exchange
          "symbol": null,
          'orderTimeMax': "", // => $algoConfig['orderTimeMax'],
          'orderSize': "", // => $algoConfig['orderSize'],
          'takeProfit': "", // => $algoConfig['takeProfit'],
          'isDebug': false, // => $algoConfig['isDebug'],
          'isDetailed': false, // => $algoConfig['isDetailed'],
          'botSide': "", // => strtoupper($algoConfig['botSide']),
          'pricePrecision': "", // => $algoConfig['pricePrecision'],
          'sellSpread': "", // => $algoConfig['sellSpread'],
          'buySpread': "", // => strtoupper($algoConfig['buySpread']),
          'maxPositionSize': "", // => $algoConfig['maxPositionSize'],
          'rangeStart': 0, // => $algoConfig['rangeStart'],
          'rangeEnd': 0, // => $algoConfig['rangeEnd']
          'stopLoss': '',
          'spreadType': 'Currency',
          'bot_type': 'scalper'
        },
        symbol: '',
        connection_id: '',
        botIdentifier: 'name'
      },
      connections: JSON.parse(localStorage.getItem('available_connections')),
      symbols: [{ symbol_id: 1, symbol_name: 'BTC/USDT' }, { symbol_id: 2, symbol_name: 'ETH/USDT' }],
      // alricDb: this.$alricDb
    }
  },
  methods: {
    closeForm() {
      this.dialog_new = false
    },
    async submitForm() {
      // @todo: @Game Can we make sure that if the OrderSize is set that Range Start and Range End have to be zero // Game : DONE
      // @todo: Make sure the form.name does not already exist in the connections list (localStorage)
      console.log(this.form)
      await guardian.addScalper('algos', this.form)
      this.closeForm()
      this.$emit('scalper-created');
      // @todo @Game Refresh the blocks to show the new connection / OR.. Append the ConnectionBlock to the end of the group // GAME : DONE
    },


    /**
     * When the user selects the connection we need related information
     * @param connectionId
     * @returns {Promise<void>}
     */
    async selectConnection(connectionId) {

      let selectedConnection = this.$alricDb.queryAll("connections",{
        query: {id: connectionId}
      })[0];

      // console.log('selectedConnection', selectedConnection)
      this.form.options.exchange_id = selectedConnection.exchange_id;
      // this.form.connection_id = selectedConnection.id;
      this.symbols = this.$alricDb.queryAll("symbols", {query: {exchange_id: selectedConnection.exchange_id}}); //JSON.stringify(tmpSymbols); //'['+tmpSymbols+']';
      // console.log('HERE',JSON.stringify(tmpSymbols))
    },
    rangeStartRangeEndcheck(){
      if (this.form.options.rangeStart > 0 || this.form.options.rangeEnd > 0){
        this.form.options.orderSize = 0
      }
    }
  },
  computed: {
    ...mapGetters([
      'getExchangeFromConnection','getConnectionFromId'
      // ...
    ])
  }
}
</script>

<style scoped></style>
