<template>
    <v-app>
      <NavBar :page_name="page_name"></NavBar>
      <v-main>
        <v-container >
          <v-row>
            <NewConnection :key="componentKey" @connection-created="rerenderConnectionBlock" ></NewConnection>
          </v-row>
          <v-row >
            <v-container fluid>
              <!-- @todo: @Game: Show this area if the user has no connections // Game : DONE-->
              <v-row v-if="connections.length == 0">
                <v-col>
                  <div class="text-center">
                    <v-card
                        class="mx-auto"
                        max-width="70%"
                    >

                      <v-card-title>
                        You have not configured any connections
                      </v-card-title>

                      <v-card-subtitle>
                        Click The 'New Connection' Button To Get Started
                      </v-card-subtitle>

                          <v-card-text>
                            Connections are how you organize and trade on each exchange. <br><br>You can have multiple connections for each exchange to use multiple accounts.
                          </v-card-text>

                    </v-card>

                  </div>
                </v-col>
              </v-row>
              <!-- @todo: @Game:: Show the table if the user wants table-view or blocks if the user wants blocks view // Game : DONE-->
              <div  v-if="connections.length != 0" class="d-flex justify-content-end pa-4 pb-0 mb-4">
                <v-btn-toggle
                  v-model="connectionsView"
                  variant="outlined"
                  divided
                  mandatory
                  density="compact"
                >
                  <v-btn>
                    <v-icon icon="mdi-view-list"></v-icon>
                  </v-btn>

                  <v-btn>
                    <v-icon icon="mdi-view-grid"></v-icon>
                  </v-btn>
                </v-btn-toggle>
              </div>

              <!-- @todo: @Game:: This Row For Table View // Game : DONE-->
              <v-row v-if="connections.length != 0 && connectionsView == 0">
                <v-col cols="12">
                  <v-table density="compact" fixed-header>
                    <thead>
                    <tr>
                      <th class="text-left">
                        Name
                      </th>
                      <th class="text-left">
                        Exchange
                      </th>
                      <th class="text-left">
                        Activated
                      </th>
                      <th>Action</th>

                    </tr>
                    </thead>
                    <tbody class="tbody-theme">
                    <tr
                        v-for="connection in connections"
                        :key="connection"
                    >
                      <td>{{ connection.name }}</td>
                      <td>{{ connection.exchange }}</td>
                      <td>{{ connection.active_since }}</td>
                      <td>
                        <div class="d-flex justify-space-around align-center flex-column flex-md-row fill-height">
                          <v-btn
                              size="x-small"
                              color="secondary"
                              @click="confirmRestart"
                          >
                            Start
                          </v-btn>

                          <v-btn
                              size="x-small"
                              color="warning"
                              @click="confirmStop"
                          >
                            Stop
                          </v-btn>

                          <v-btn
                              size="x-small"
                              color="primary"
                          >
                            Manage Symbols
                          </v-btn>
                          <v-btn
                              size="x-small"
                              color="red"
                              @click="confirmDelete(connection.id)"
                          >
                            Delete
                          </v-btn>


                        </div>
                      </td>
                    </tr>
                    </tbody>
                  </v-table>
                </v-col>
              </v-row>
              <!-- @todo: @Game:: This Row For Blocks View // Game : DONE -->
              <v-row v-if="connectionsView == 1">
                  <ConnectionBlock v-for="connection in connections" :key="connection.id"
                                   :v-if="connection.display"
                                   :exchange_id="connection.exchange_id"
                                   :exchange="getExchangeName(connection.exchange_id)"
                                   :type="connection.type"
                                   :connection_name="connection.name"
                                   :connection_id="connection.id"
                                   :symbols="connection.symbols"
                                   :active_since="connection.active_since"
                                   @connection-deleted="deleteConnection(connection.id)"></ConnectionBlock>


              </v-row>

            </v-container>

          </v-row>

        </v-container>
      </v-main>
    </v-app>
</template>
<script>

import NavBar from '../components/NavBar.vue'
import ConnectionBlock from "@/components/blocks/ConnectionBlock.vue";
import NewConnection from "@/components/forms/NewConnection.vue";
import {mapGetters} from "vuex";
import GuardianProvider from "@/resources/guardian_provider";
// import localStorageDB from "localstoragedb";

const guardianService = new GuardianProvider()

  export default {
    components: {
      NewConnection,
      NavBar, ConnectionBlock
    },
    data: () => ({
      page_name: 'Connections',
      componentKey: 0,
      connections: [],
      connectionsView: 1,
      // alricDb: new localStorageDB("alricDb", localStorage),
    }),
    watch: {
      group () {
        // this.drawer = false
      },
    },
    /*beforeCreate() { // @todo: @Daniel disabled - might need it back.. not sure
      this.$store.dispatch("setAvailableConnections");
    },*/
    beforeMount() {
    // Fetch the connections data and update the connections array
    this.$store.dispatch('setAvailableConnections', this.$alricDb)
    this.connections = this.$alricDb.queryAll("connections");
    },
    mounted() {
      this.setAllConnectionsSymbols();
      this.$store.dispatch("setAvailableSymbols", this.$alricDb).then(() => {})
    },
    computed: {
      ...mapGetters([
        'getExchangeFromConnection','getConnectionFromId'
        // ...
      ]),
      // getConnections() {
      //   this.connections = this.$alricDb.queryAll("connections")
      //   return this.connections
      // },
    },
    methods: {
      forceRerender() {
        this.componentKey += 1;
      },
      getExchangeName(exchangeId) {
        let exchange = this.$alricDb.queryAll("exchanges", {
          query: {id: `${exchangeId}`}
        })[0];
        // console.log('EXCHANGE:',exchange)
        if( undefined ===  exchange || undefined ===  exchange.name) {
          return 'Binance'
        }
        return exchange.name
      },
      async inArray(needle, haystack) {
        var length = haystack.length;
        for(var i = 0; i < length; i++) {
          if(haystack[i] == needle) return true;
        }
        return false;
      },
      async setAllConnectionsSymbols() {

        let connections = this.$alricDb.queryAll("connections")

        let i = 0;
        if (connections.length){
          do {
            if( connections[i].name !== 'Default') {

              let tSymbols = await guardianService.getConnectionSymbols(connections[i].id)
                if( tSymbols.length > 0 ) {
                this.$alricDb.truncate("connection_symbols");
                let a = 0;
                do {

                  this.$alricDb.insert("connection_symbols", {
                    id: tSymbols[a].id,
                    connection_id: tSymbols[a].connection_id,
                    symbol_id: tSymbols[a].symbol_id,
                    symbol_name: tSymbols[a].symbol.name,
                    active: tSymbols[a].active
                  });

                  a++
                } while (a < tSymbols.length);
                this.$alricDb.commit();
              }

            }

            i++;
          } while (i < connections.length)
        }

      },
    confirm() { // @note: https://www.codenotfound.dev/article/setup-vue-sweetalert2-as-a-global-helper-in-vue-2
      this.$root.$confirm({
        title: "Confirm",
        message: "Confirm message",
        options: {
          btnDisagree: "No",
          btnAgree: "Yes",
        },
        agree: this.agree,
        disagree: this.disagree,
      });
    },
    agree() {
      console.log("agree!");
    },
    disagree() {
      console.log("disagree!");
    },
    confirmRestart() {
      this.$root.$confirm({
        title: "Restart Connection?",
        message: "This will also restart all systems using this connection",
        options: {
          btnDisagree: "No",
          btnAgree: "Yes",
        },
        agree: () => this.agreeParams("passed 1", "passed 2"),
        disagree: () => {
          console.log("disagree callback");
        },
      });
    },
    confirmStop() {
      this.$root.$confirm({
        title: "Stop Connection?",
        message: "This will also STOP all systems using this connection",
        options: {
          btnDisagree: "No",
          btnAgree: "Yes",
        },
        agree: () => this.agreeParams("passed 1", "passed 2"),
        disagree: () => {
          console.log("disagree callback");
        },
      });
    },
    reRender(){
      this.$store.dispatch("setAvailableConnections", this.$alricDb).then(() => {
        window.location.reload();
      })
    },
    confirmDelete(connectionId) {
      this.$root.$confirm({
        title: "Delete Connection?",
        message: "This will permanently remove this connection",
        options: {
          btnDisagree: "No",
          btnAgree: "Yes",
        },
        agree: () => {
          guardianService.deleteConnection(connectionId).then(() => {
              // @todo @Game - Need to remove (self) this block after connection is deleted.
            this.$alricDb.deleteRows("connections", { id: connectionId});
            this.connections = this.$alricDb.queryAll("connections")
            })
          },
        disagree: () => {
          console.log("disagree callback");
        },
      });
    },
    deleteConnection(connectionId) {
      this.$alricDb.deleteRows("connections", { id: connectionId});
      this.connections = this.$alricDb.queryAll("connections")
    },
    agreeParams(param1, param2) {
      console.log(`param 1: ${param1}, param 2: ${param2}`);
    },
    async rerenderConnectionBlock(){
      await this.$store.dispatch('setAvailableConnections', this.$alricDb)
      this.connections = this.$alricDb.queryAll("connections");
    },
    closeForm(){
      this.dialog_new = false;
    },
    }

  }
</script>

<style>
  .tbody-theme tr {
    background: rgb(var(--v-theme-surface));
    color: rgba(var(--v-theme-on-surface), var(--v-high-emphasis-opacity)) !important;
    font-weight: normal;
  }
  .tbody-theme tr:hover {
    background: rgba(var(--v-theme-on-surface-variant))
  }
</style>
