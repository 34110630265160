<template>
  <v-dialog
      v-model="dialog_new"
      persistent
      max-width="60%"
  >
    <template v-slot:activator="{ props }">
      <v-btn
          class="ma-2"
          v-bind="props"
      >
        <v-icon
            start
            icon="mdi-plus-circle"
        ></v-icon>
        New Connection
      </v-btn>
    </template>
    <v-card>
      <v-form @submit.prevent="onSubmit" >
      <v-card-title>
        <span class="text-h5">New Connection</span>
      </v-card-title>
      <v-card-text>
        <v-container>

            <v-row>
              <v-col
                  cols="12"
                  sm="6"
                  md="4"
              >
                <v-text-field v-model="form.name"
                              label="Connection Name *"
                              required
                              :rules="[required,alreadyExist]"
                ></v-text-field>


              </v-col>
              <v-col
                  cols="12"
                  sm="6"
                  md="4"
              >
                <!-- @todo: this list should come from the API/localStorage -->
                <v-autocomplete v-model="form.exchange_id"
                                :items="getExchanges"
                                item-title="name"
                                item-value="id"
                                :rules="[required]"
                                label="Exchange *"
                ></v-autocomplete>
              </v-col>
              <v-col
                  cols="12"
                  sm="6"
                  md="4"
              >
                <v-select v-model="form.trade_type"
                    :items="['Spot', 'Futures']"
                    label="Type *"
                    required
                          :rules="[required]"
                ></v-select>
              </v-col>
              <v-col cols="12">
                <v-text-field v-model="form.api_key"
                    label="API KEY *"
                    required
                              :rules="[required]"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field  v-model="form.api_secret"
                    label="API SECRET *"
                    type="text"
                    required
                               :rules="[required]"

                ></v-text-field>
              </v-col>
            </v-row>

        </v-container>
        <small>*indicates required field</small>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            color="blue-darken-1"
            variant="text"
            @click="dialog_new = false"
        >
          Close
        </v-btn>
        <v-btn
            color="blue-darken-1"
            variant="text"
            :disabled="!form"
            type="submit"
        >
          Save
        </v-btn>
      </v-card-actions>
      </v-form>
    </v-card>

  </v-dialog>
</template>

<script>
import GuardianProvider from "@/resources/guardian_provider";
const guardian = new GuardianProvider();

export default {
  name: "NewConnection",
  data(){
    return{
      dialog_new: false,
      form: {
        name: null,
        // user_id: 1,
        trade_type: null,
        exchange_id: null,
        api_key: null,
        api_secret: null,
      }
    }
  },
  methods:{
    closeForm(){
      this.dialog_new = false;
    },
    reRender(){
        this.$store.dispatch("setAvailableConnections", this.$alricDb).then(() => {
          window.location.reload();
        })
    },
    async onSubmit() {
      // @todo: @Game Make sure the form.name does not already exist in the connections list (localStorage) // Game : Done ... alreadyExist() method check
      if( this.form.api_key && this.form.api_secret && this.form.trade_type && this.form.name && this.form.exchange_id ){
        let availableConnections = JSON.parse(localStorage.getItem('available_connections'))
        let isConnectionExist = availableConnections.find(connection => connection.name === this.form.name)

        if (!isConnectionExist){
          await guardian.saveConnection('connections',this.form)   // Game : DONE!
          this.$store.dispatch("setAvailableSymbols", this.$alricDb).then(() => {
            this.closeForm();
            this.$emit('connection-created');  // Game : emit to parent compoent for rerendering
            // this.reRender()
          })

        }
      }

      // @todo @Game Refresh the blocks to show the new connection / OR.. Append the ConnectionBlock to the end of the group  // Game : Done!

    },
    required (v) {
      return !!v || 'Field is required'
    },
    alreadyExist (v) {
      console.log('v',v);
      let availableConnections = JSON.parse(localStorage.getItem('available_connections'))
      let isConnectionExist = availableConnections.find(connection => connection.name === v)
      return !isConnectionExist || 'Connection name does already exist'
    },
  },
  computed: {
    getExchanges(){
      // @todo @game This should use alricDb
      let availableExchanges = localStorage.getItem('available_exchanges');
      availableExchanges = JSON.parse(availableExchanges);
      return availableExchanges
    }
  }
}
</script>

<style scoped>

</style>
