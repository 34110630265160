export const SET_USER = 'SET_USER'
export const SET_CONNECTIONS = 'SET_CONNECTIONS'
export const SET_EXCHANGES = 'SET_EXCHANGES'
export const SET_TICKERS = 'SET_TICKERS'
export const SET_TRADING_EXCHANGE = 'SET_TRADING_EXCHANGE'
export const SET_TRADING_CONNECTION = 'SET_TRADING_CONNECTION'
export const SET_TRADING_SYMBOL = 'SET_TRADING_SYMBOL'
export const SET_EXCHANGE_SYMBOLS = 'SET_EXCHANGE_SYMBOLS'
export const SET_CONNECTION_SYMBOLS = 'SET_CONNECTION_SYMBOLS'
export const SET_ALGOS = 'SET_ALGOS'
export const SET_AVAILABLE_SYMBOLS = 'SET_AVAILABLE_SYMBOLS'
