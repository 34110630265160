<template>
  <div class="order-book mb15">
    <v-tabs v-model="tab">
      <v-tab value="order-book">Order Book</v-tab>
      <!--      <v-tab value="two">Item Two</v-tab>
            <v-tab value="three">Item Three</v-tab>-->
    </v-tabs>
    <v-window v-model="tab" height="100%">
      <v-window-item value="order-book">
        <v-table>
          <thead>
            <!--      <tr>
                  <th colspan="3" class="text-center" >Order Book</th>
                  <th></th>
                  <th></th>
                </tr>-->
            <tr>
              <th>Price</th>
              <th>Volume</th>
              <th>Total</th>
            </tr>
          </thead>
          <tbody>
          <tr v-for="ask in asks" > <!--  -->
            <td class="text-left">{{ Math.round(((ask[0] * 1) + Number.EPSILON) * 100) / 100 }}</td>
            <td class="text-left">{{ ask[1] }}</td>
            <td class="text-left">{{ Math.round(((ask[0] * ask[1]) + Number.EPSILON) * 100) / 100 }}</td>
          </tr>
          </tbody>
          <tbody class="ob-heading">
            <tr>
              <td>
                <span>Last Price</span>
                {{ lastTradedPrice }}
              </td>
              <td>
                <span>USD</span>
                148.65
              </td>
              <td class="red">
                <span>Change</span>
                -0.51%
              </td>
            </tr>
          </tbody>
          <tbody>
          <tr v-for="bid in bids" > <!--  -->
            <td class="text-left">{{ Math.round(((bid[0] * 1) + Number.EPSILON) * 100) / 100 }}</td>
            <td class="text-left">{{ bid[1] }}</td>
            <td class="text-left">{{ Math.round(((bid[0] * bid[1]) + Number.EPSILON) * 100) / 100 }}</td>

          </tr>

          </tbody>
        </v-table>
      </v-window-item>
    </v-window>

  </div>

</template>

<script>
// import axios from 'axios';
import ccxt from "ccxt";

export default {
  data() {
    return {
      asks: [],
      bids: [],
      lastTradedPrice: 0,
      tab: null,
      feedError: false,
      activeExchange: localStorage.getItem('trading_connection') ? localStorage.getItem('trading_connection').toLowerCase() : 'binance',
      symbol: 'BTCUSDT',
      limit: 10,
      intervalId: null
    };
  },
  mounted() {
    this.fetchOrderbookData()
    this.intervalId = setInterval(() => {
      this.fetchOrderbookData()
    }, 3000)
    // console.log('intervalId ' + this.intervalId)
  },
  unmounted() {
    clearInterval(this.intervalId)
  },
  methods: {
    async fetchOrderbookData() {
return;
     /* setInterval(async () => {
        if (this.feedError === false) {

          try {
            let exchange = new ccxt.pro[this.activeExchange]({newUpdates: false})
            const orderbook = await exchange.watchOrderBook(this.symbol, this.limit)
            // your reaction to the update takes place here
            // you arrive here after receiving the update from the exchange in real time
            // console.log (orderbook) // every update
            this.asks = orderbook.asks;
            this.bids = orderbook.bids;
            this.lastTradedPrice = (this.asks[0][0] + this.bids[0][0]) / 2;
          } catch (e) {
            console.log(e)
            this.feedError = true;
            // throw e // uncomment to stop the loop on exceptions
          }

        }

      }, 2000);*/


      /*this.feedError= true;
      // const ccxt = require('ccxt')
      // const exchange = new ccxt[activeExchange]({ apiKey, secret });
      const exchange = new ccxt.pro[this.activeExchange]({newUpdates: false})
      //
// console.log(exchange)
      while (true) {
        try {
          const orderbook = await exchange.watchOrderBook(this.symbol, this.limit)
          // your reaction to the update takes place here
          // you arrive here after receiving the update from the exchange in real time
          // console.log (orderbook) // every update
          this.asks = orderbook.asks;
          this.bids = orderbook.bids;
        } catch (e) {
          console.log(e)
          this.feedError = true;
          // throw e // uncomment to stop the loop on exceptions
        }
        // await exchange.sleep (100) // every 100 ms
      }*/


      /*await exchange.watchOrderBook(this.symbol, this.limit)
      while (true) {
        // your reaction takes place here
        // you arrive here every 100 ms regardless of whether there was an update or not
        // in throttling mode offloading the orderbook with .limit () is required
        console.log (exchange.orderbooks[this.symbol].limit (this.limit))
        await exchange.sleep (100) // every 100 ms
      }*/
/*

      axios.get('https://api.binance.com/api/v3/depth', {
        params: {
          symbol: 'BTCUSDT',
          limit: 10
        }
      }).then((response) => {
        this.asks = response.data.asks;
        this.bids = response.data.bids;
        /!*this.lastTradedPrice = (this.asks[0][0] + this.bids[0][0]) / 2;*!/
      }).catch((error) => {
        this.feedError = true;
        console.error(error);
      });*/
    },
    getAskBackground(price) {
      if (price >= this.lastTradedPrice) {
        return 'lightgreen';
      }
      return 'black';
    },
    getBidBackground(price) {
      if (price >= this.lastTradedPrice) {
        return 'red';
      }
      return 'black';
    },
  }
};
</script>

<style>
table {
  margin: 20px 0;
}
</style>
