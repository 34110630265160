<template>
  <div class="main-chart mb15">
    <button @click="callChildMethod" hidden>{{ updateTheme }}</button>
    <button @click="callChildMethod" hidden>
      {{ getSelectedTradingSymbol }}
    </button>
    <VueTradingView
      ref="tradingChart"
      :options="{
        symbol: symbolChart,
        theme: themeIs,
        width: '100%',
        height: '550px',
        allow_symbol_change: true
      }"
    />
  </div>
</template>

<script>
import VueTradingView from 'vue-trading-view/src/vue-trading-view.vue'

export default {
  components: {
    VueTradingView
  },
  data() {
    return {
      themeIs: this.$root.theme.global.name.value,
      symbolChart:
        this.$store.state.trading_symbol.exchange +
        ':' +
        this.$store.state.trading_symbol.symbol.replace('/', '')
    }
  },
  methods: {
    callChildMethod() {
      this.$refs.tradingChart.initWidget()
    }
  },
  computed: {
    updateTheme() {
      this.themeIs = this.$root.theme.global.name.value
      return this.$root.theme.global.name.value
    },
    // updateExchangeSymbol() {
    //   this.themeIs = this.$root.theme.global.name.value
    //   return this.$root.theme.global.name.value
    // },
    getSelectedTradingSymbol() {
      this.symbolChart =
        this.$store.state.trading_symbol.exchange +
        ':' +
        this.$store.state.trading_symbol.symbol.replace('/', '')
      return (
        this.$store.state.trading_symbol.exchange +
        ':' +
        this.$store.state.trading_symbol.symbol.replace('/', '')
      )
    }
  },
  watch: {
    themeIs: {
      handler: function (val, oldVal) {
        this.callChildMethod()
      },
      deep: true
    },
    symbolChart: {
      handler: function (val, oldVal) {
        this.callChildMethod()
      },
      deep: true
    }
  }
}
</script>
