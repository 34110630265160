import HttpRequest from './http_request'

class UserProvider extends HttpRequest {
  constructor () {
    super('/')
  }

  async getUser () {
    const {data} = await this.get('/me') // /api/v1/me
    return data
  }

  async doLogin (email, passcode) {
    let details = JSON.stringify({
      "email": email,
      "password": passcode
    });

    const {data} = await this.request('POST', '/v1/login', details)
    // console.log(data)
    sessionStorage.setItem('access_token', data.access_token);

    return data
  }

}

export default UserProvider
