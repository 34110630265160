<template>
  <v-app>
    <NavBar :page_name="page_name"></NavBar>
    <v-main>
      <v-container >
        <v-row>
          <NewMarkerMaker @scalper-created="rerenderAlgoBlock"></NewMarkerMaker>
          <NewScalper @scalper-created="rerenderAlgoBlock"></NewScalper>
        </v-row>
        <v-row>
          <AlgoBlock v-for="algorithm in algorithms" :key="algorithm"
                     :exchange_name="algorithm.exchange_name"
                     :connection_name="algorithm.connection_name"
                     :symbol_name="algorithm.symbol_name"
                     :friendly_name="algorithm.friendly_name"
                     :bot_type="algorithm.bot_type"
                     :algo_id="algorithm.algo_id"
                     @algo-deleted="deleteBot(algorithm.algo_id)" ></AlgoBlock>
        </v-row>
      </v-container>
    </v-main>
  </v-app>



</template>

<script>
import AlgoBlock from "@/components/blocks/AlgoBlock.vue";
import NavBar from "@/components/NavBar.vue";
import NewMarkerMaker from "@/components/forms/NewMarkerMaker.vue";
import NewScalper from "@/components/forms/NewScalper.vue";
// import localStorageDB from "localstoragedb";
import {mapGetters} from "vuex";
export default {
  name: "AlgosView",
  // props: ['algorithms'],
  components: {
    NewMarkerMaker,
    NewScalper,
    AlgoBlock, NavBar
  },
  data() {
    return {
      algorithms: this.buildAlgorithmsArray(),
      page_name: "Algo's View",
    }
  },
  computed: {
    ...mapGetters([
      'getExchangeFromConnection','getConnectionFromId', 'getSymbolNameFromId'
      // ...
    ])
  },
  methods: {
    deleteBot(algoId) {
      this.$alricDb.deleteRows("algos", { id: algoId});
      this.algorithms = this.buildAlgorithmsArray()
    },
     buildAlgorithmsArray(){

       let algos = this.$alricDb.queryAll('algos')

       let i = 0;
       let algosArray = []
       let thisArray = []
       if( algos.length ){
         do{
           // console.log('69: ALGOS:', algos[i])
           let currentConnection = this.$alricDb.queryAll('connections', { query: {id : algos[i].connection_id}})[0]

             thisArray = {
               exchange_name: this.$store.getters.getExchangeFromConnection({id : algos[i].connection_id, alricDb :this.$alricDb}).name, //getExchangeFromConnection(algos[i].connection_id),
               connection_name: currentConnection.name,
               symbol_name: this.$store.getters.getSymbolNameFromId({id : algos[i].symbol_id, alricDb :this.$alricDb}),
               friendly_name: algos[i].name,
               bot_type: algos[i].algo_config.bot_type,
               algo_id: algos[i].id
             }

             algosArray.push(thisArray)

           i++
         }while (i < algos.length)
         // console.log('ALL ALGOS:',algosArray)
       }

      return algosArray;
    },
    async rerenderAlgoBlock(){
      await this.$store.dispatch('setAvailableAlgos', this.$alricDb)
      this.algorithms = this.buildAlgorithmsArray()
    }
  },
  beforeMount() {
    this.$store.dispatch('setAvailableAlgos', this.$alricDb)
  }
}
</script>

<style scoped>

</style>
