import {createStore} from 'vuex'
import UserProvider from '@/resources/user_provider'
import GuardianProvider from "@/resources/guardian_provider";

import {
  SET_CONNECTIONS, SET_EXCHANGE_SYMBOLS, SET_CONNECTION_SYMBOLS, SET_ALGOS,
  SET_AVAILABLE_SYMBOLS,
  SET_EXCHANGES,
  SET_TICKERS,
  SET_TRADING_CONNECTION,
  SET_TRADING_EXCHANGE,
  SET_TRADING_SYMBOL,
  SET_USER
} from "@/resources/mutation-types";

const ccxt = require('ccxt')
const userService = new UserProvider()
const guardianService = new GuardianProvider()

export default createStore({
  state: {
    user: {
      logged_in: false,
    },
    email: '',
    trading_symbol: { exchange: 'BINANCE', symbol: 'BTC/USDT', connection: null },// @todo @Game Get from user's /me at login  // Game : changed to get from available connection
    exchanges: [],
    connections: [],

  },
  getters: {
    getExchangeFromConnection: (state) => (data) => { // exchange_id

      let connection = data.alricDb.queryAll('connections', { query: {id: data.id}})[0]
      let exchange = data.alricDb.queryAll('exchanges', { query: {id: connection.exchange_id}})
      return exchange[0]

    },
    getSymbolNameFromId: () => (data) => {
      let theSymbol = data.alricDb.queryAll("symbols", { query: { symbol_id: data.id } } )
      if ( undefined !== theSymbol[0]){
        return theSymbol[0].symbol_name
      }
      return 'null'
    },
    getConnectionFromId: (state) => (id) => {
      return state.connections.find(connection => connection.id === id);
    },
    getProExchanges: (state) => () => {
      let exchanges = state.exchanges.find(exchange => exchange.is_pro == true);
      return exchanges // @todo: exchange_id from the connection
    },

  },
  mutations: {
    login(state, email) {
      state.email = email
      state.user.logged_in = true;
      localStorage.setItem('email', email)
    },
    logout(state) {
      state.email = ''
      localStorage.clear();
      sessionStorage.clear();
    },
    initialiseStore(state) {
      if (sessionStorage.getItem('access_token')) {
        state.email = localStorage.getItem('email')
        state.user.logged_in = true;
      }
      let defaultExchange = 'BINANCE'
      let defaultSymbol = 'BTC/USDT'
      // @todo @Game Using this causes issues (in ConnectionBlock) with using the exchange name:: Should have block telling user to add a connection to continue
      let defaultConnection = '{"id":"2","name":"BINANCE","exchange":5,"active_since":"2023-02-15 16:28:28"}'

      if( state.user.logged_in === true ){

        if (localStorage.getItem('trading_connection')) {
          defaultConnection = localStorage.getItem('trading_connection')
        }
        else {
          localStorage.setItem('trading_connection', defaultConnection);
        }

        state.trading_symbol.connection = JSON.parse(defaultConnection)

        if (localStorage.getItem('trading_exchange')) {
          defaultExchange = localStorage.getItem('trading_exchange')
        }
        localStorage.setItem('trading_exchange', defaultExchange.toUpperCase());
        state.trading_symbol.exchange = defaultExchange.toUpperCase()

        if (localStorage.getItem('trading_symbol')) {
          defaultSymbol = localStorage.getItem('trading_symbol')
        }
        localStorage.setItem('trading_symbol', defaultSymbol.toUpperCase());
        state.trading_symbol.symbol = defaultSymbol.toUpperCase()

        if( undefined === localStorage.getItem('available_connections') || localStorage.getItem('available_connections') == null) {
          localStorage.setItem('available_connections', '[]')
        }

      }
    },

    [SET_USER] (state, data) {
      state.token = data.token // @todo: Question... With the token in state - do we need to have it in sessionStorage?
    },
    [SET_CONNECTIONS](state, data) {
      state.connections = data
      localStorage.setItem('available_connections', JSON.stringify(data))
    },
    [SET_ALGOS](state, data) {
      state.available_algo = data
    },
    [SET_CONNECTION_SYMBOLS](state, data) {
      state.connection_symbols = data
    },
    [SET_AVAILABLE_SYMBOLS](state, data) {
        state.connection_symbols = data
    },
    [SET_EXCHANGES](state, data) {
      state.exchanges = data
      localStorage.setItem('available_exchanges', JSON.stringify(data))
    },
    [SET_TICKERS](state, data) {
      state.tickers = data
    },
    [SET_TRADING_EXCHANGE](state, data) {
      state.trading_symbol.exchange = data
      localStorage.setItem('trading_exchange', data)
    },
    [SET_TRADING_CONNECTION](state, data) {
      let activeConnection = state.connections.find(connection => connection.id === data)
      state.trading_symbol.connection = activeConnection
      // console.log('CXN',activeConnection )
      localStorage.setItem('trading_connection', JSON.stringify(activeConnection))
    },
    [SET_TRADING_SYMBOL](state, data) {
      state.trading_symbol.symbol = data
      localStorage.setItem('trading_symbol', data)
    },
    [SET_EXCHANGE_SYMBOLS](state, data) {
      localStorage.setItem('temp_exchange_symbols', data)
    },
  },
  actions: {

    async setExchangeTicker({ commit }, data) {

      console.log('connection_id',data.connectionId);

      // return;
      let activeSymbols = await guardianService.getConnectionSymbols(data.connection_id)
      let activeExchange = this.state.trading_symbol.exchange
      let exchange_title = activeExchange.toLowerCase()

      console.log('activeSymbols',activeSymbols);

      let symbols = data.db.queryAll("symbols", { query: {exchange_id: 115}})

      // console.log('symbolList',symbols)

      let symbolList = []

      symbols.forEach(item => {
        symbolList.push(item.symbol_name)
      })

      console.log('symbolList',symbolList);

      // let quoteSymbol = 'USDT'
      // let filterSymbol = []
      // @todo: @Game: This should use the trading_pairs set for the active connection - not get all from the exchange // GAME : Change to use availabe symbol for these ccxt query

      // for (let trading_pair in activeSymbols.data.attributes) {
      //   filterSymbol.push(trading_pair.symbol)
      // }

      console.log('ccxt fetch exchange data ==>', exchange_title)

      const exchange = new ccxt[exchange_title]

      console.log('exchange',exchange);
      let tickers = null
      let tickerList = []
    // @todo this should get 'activeSymbols' that was put into localStorage during login.
      if (exchange.has['fetchTickers']) {
        // tickers = await exchange.fetchTickers(filterSymbol)
        tickers = await exchange.fetchTickers(symbolList)
        console.log('tickers',tickers);

        for (const [key, value] of Object.entries(tickers)) {
          tickerList.push({
            symbol: key,
            price: value.average.toLocaleString('en-US'),
            daypercentchange: value.change + '%',
            dayvolumechange: Math.trunc(value.baseVolume).toLocaleString(
              'en-US'
            )
          })
        }

        console.log(tickerList)
      }

      commit('SET_TICKERS', tickerList)
    },

    async doLogin ({commit}, data) {

      if( data.db.tableExists("exchanges") === false ){
        await data.db.createTable("exchanges", ["id", "name", "public_api_url", "public_ws_url", "private_api_url", "private_ws_url", "config_options", "has_ccxt"]);
      }
      if( data.db.tableExists("connections") === false ){
        await data.db.createTable("connections", ["id", "name", "exchange_id", "api_key", "api_secret", "active_since", "type"]);
      }
      if( data.db.tableExists("connection_symbols") === false ){
        await data.db.createTable("connection_symbols", ["id", "connection_id", "symbol_id", "symbol_name", "active", "symbol_config"]);
      }
      if( data.db.tableExists("algos") === false ){
        await data.db.createTable("algos", ["id", "name", "connection_id", "symbol_id", "algo_config", "status","service_name","redis_url"]);
      }
      await data.db.commit();

      await userService.doLogin(data.form.email, data.form.password).then(async (resp) => {

         this.dispatch('setAvailableConnections', data.db).then(() => {
           this.dispatch('setAvailableExchanges', data.db).then(() => {
             this.dispatch('setAvailableSymbols', data.db).then(() => {
               this.dispatch('setConnectionSymbols', data.db).then(() => {});
             })
          })
        })

        commit('SET_USER', resp)
        // console.log(resp)
      })

    },

    async setAvailableConnections({ commit }, db){
      let activeConnections;
      if( sessionStorage.getItem('access_token') ) {
        // let alricDb = new localStorageDB("alricDb", localStorage);
        activeConnections = await guardianService.getConnections();
        let i = 0;
        let cxns = [];
        // const proxy = 'http://localhost:4000/'

        db.truncate("connections");

        if( activeConnections.length > 0 ) {
          do {

            db.insert("connections", {
              id: parseInt(activeConnections[i].id),
              name: activeConnections[i].name,
              exchange_id: parseInt(activeConnections[i].exchange_id), /*"symbols":tickerList,*/
              active_since: activeConnections[i].updated_at,
              type: activeConnections[i].trade_type
            });
            // "id", "name", "exchange_id", "api_key", "api_secret", "active_since", "type"
            cxns.push({
              "id": activeConnections[i].id,
              "name": activeConnections[i].name,
              "exchange": activeConnections[i].exchange_id, /*"symbols":tickerList,*/
              "active_since": activeConnections[i].updated_at,
              "type": activeConnections[i].trade_type
            })
            i++;

          } while (i < activeConnections.length);

          db.commit();
        }

        commit('SET_CONNECTIONS', cxns);

      }

    },

    async setAvailableAlgos({ commit }, db){

      let activeAlgos;
      // let alricDb = new localStorageDB("alricDb", localStorage);
      if( sessionStorage.getItem('access_token') ) {

        if( db.tableExists("algos") === false ){
          await db.createTable("algos", ["id", "name", "connection_id", "symbol_id", "algo_config", "status","service_name","redis_url"]);

        }
        activeAlgos = await guardianService.getAlgos();
        // console.log(activeAlgos);
        let i = 0;

        if( activeAlgos.length > 0 ) {
          db.truncate("algos");

          do {

            db.insert("algos", {
              id: parseInt(activeAlgos[i].id),
              name: activeAlgos[i].name,
              connection_id: parseInt(activeAlgos[i].connection_id),
              symbol_id: parseInt(activeAlgos[i].symbol_id),
              algo_config: activeAlgos[i].algo_config,
              status: activeAlgos[i].active,
              service_name: activeAlgos[i].service_name,
              redis_url: activeAlgos[i].redis_url
            });

            i++;

          } while (i < activeAlgos.length);

          db.commit();
        }
        commit('SET_ALGOS', true)
      }

    },

    async setAvailableExchanges({ commit }, db){

      let availableExchanges;
      if( sessionStorage.getItem('access_token') ) {
        // let alricDb = new localStorageDB("alricDb", localStorage);
        availableExchanges = await guardianService.getExchanges();
        let cxns = [];
        let i = 0;
        // insert exchange data
        db.truncate("exchanges");

        do {

            db.insert("exchanges", {
              id: parseInt(availableExchanges[i].id),
              name: availableExchanges[i].name.toUpperCase(),
              public_api_url: availableExchanges[i].public_api_url,
              public_ws_url: availableExchanges[i].public_ws_url,
              private_api_url: availableExchanges[i].private_api_url,
              private_ws_url: availableExchanges[i].private_ws_url,
              config_options: availableExchanges[i].config_options,
              has_ccxt: availableExchanges[i].has_ccxt
            });

            cxns.push({
              "id": availableExchanges[i].id,
              "name": availableExchanges[i].name.toUpperCase(),
              "public_api_url": availableExchanges[i].public_api_url,
              "public_ws_url": availableExchanges[i].public_ws_url,
              "private_api_url": availableExchanges[i].public_api_url,
              "private_ws_url": availableExchanges[i].private_ws_url,
              "config_options": availableExchanges[i].config_options,
              "has_ccxt": availableExchanges[i].has_ccxt
            });


          i++;
        } while (i < availableExchanges.length);

        db.commit();
        commit('SET_EXCHANGES', cxns)
      }

    },

    async setAvailableSymbols({ commit }, db){

      //let availableExchanges;
      if( sessionStorage.getItem('access_token') ) {

        // let alricDb = new localStorageDB("alricDb", localStorage);
        if( db.tableExists("symbols") === false ){
          db.createTable("symbols", ["symbol_id", "symbol_name", "exchange_id"]);
          db.commit();
        }
        // await guardianService.getSymbols();

        let connections = db.queryAll('connections');
        let i = 0 // connections
        if( connections.length > 0 ) {
          db.truncate("symbols");
          do { // loop connections
            let syms = await guardianService.getSymbols(connections[i].exchange_id);

            let s = 0 // symbols
            do { // loop symbols from api

              db.insert("symbols", {
                exchange_id: parseInt(connections[i].exchange_id),
                symbol_id: parseInt(syms[s].id),
                symbol_name: syms[s].uppercaseId
              });
              s++
            } while (s < syms.length)

            db.commit();

            i++
          } while (i < connections.length)
        }

      }
      commit('SET_AVAILABLE_SYMBOLS', true)
    },

    async setConnectionSymbols({ commit }, db){

      // let alricDb = new localStorageDB("alricDb", localStorage);
      let tConnections =  db.queryAll('connections');

      let a = 0;
      db.truncate("connection_symbols");
      do {

        let tSymbols = await guardianService.getConnectionSymbols(tConnections[a].id)
        let i = 0;
        do {

          db.insert("connection_symbols", {
            id: tSymbols[i].id,
            connection_id: tSymbols[i].connection_id,
            symbol_id: tSymbols[i].symbol_id,
            symbol_name: tSymbols[i].symbol.uppercaseId,
            symbol_config: tSymbols[i].symbol,
            active: tSymbols[i].active
          });

          i++
        } while ( i < tSymbols.length );

        a++;
      } while (a < tConnections.length);
      db.commit();

      commit('SET_CONNECTION_SYMBOLS', true)
    },

    // }
  },
  modules: {
    // @todo @Game - lets use modules so the main store doesnt get too large to understand/follow :: /Game understood
    // @todo: https://vuex.vuejs.org/guide/modules.html
    // inherits the namespace from parent module
    tradeForms: {
      namespaced: true,
      state: () => ({

      }),
      getters: {
        profile () {}, // -> getters['account/profile']
        getExchangeFromConnection(){},
        getSymbolNameFromId(){},
      }
    },
  }
})
