<template>
  <div class="market-history">
    <v-tabs
        v-model="tab"
    >
      <v-tab value="recent-trades">Recent Trades</v-tab>
<!--      <v-tab value="two">Item Two</v-tab>
      <v-tab value="three">Item Three</v-tab>-->
    </v-tabs>
    <v-window v-model="tab" height="100%">
      <v-window-item value="recent-trades">
        <v-table class="table">
          <thead>
          <tr>
            <th>Time</th>
            <th>Price</th>
            <th>Volume</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="trade in recentTrades" v-bind:key="trade.t">
            <td>{{ trade.time }}</td>
            <td :class="trade.priceClass">{{ trade.price }}</td>
            <td>{{ trade.amount }}</td>
          </tr>
          </tbody>
        </v-table>
      </v-window-item>

<!--      <v-window-item value="two">
        Two
      </v-window-item>

      <v-window-item value="three">
        Three
      </v-window-item>-->
    </v-window>


  </div>
</template>

<script>

export default {
  props: ['connection_name', 'symbol_name', 'exchange'],
  data() {
    return {
      recentTrades: [],
      stream: {
        exchangeId: this.exchange,
        symbol: this.symbol_name
      },
      tab: null,
    };
  },
  mounted() {
    const ws = new WebSocket("wss://stream.binance.com:9443/ws/btcusdt@trade");
    ws.onmessage = (event) => {

      const trade = JSON.parse(event.data);

      this.recentTrades.unshift({
        time: new Date(trade.T).toLocaleTimeString(),
        price: trade.p,
        amount: trade.q
      });

      // Only keep the latest 10 trades
      if (this.recentTrades.length > 10) {
        this.recentTrades.pop();
      }
    };
  }
};


</script>
<style scoped>
table, tbody {
  height: 100%;
}
</style>
