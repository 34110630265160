import { createRouter, createWebHistory } from 'vue-router'
import DashboardView from '../views/DashboardView.vue'
import ConnectionsView from "@/views/ConnectionsView.vue";
import store from '@/store/index';
import AlgosView from "@/views/AlgosView.vue";
import ProfileView from "@/views/ProfileView.vue";

const isuserLoggedIn = false

const routes = [
  {
    path: '/',
    redirect: '/login',
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "about" */ '../views/LoginView.vue')
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: DashboardView,
    meta : {
      needsAuth: true
    }
  },
  {
    path: '/connections',
    name: 'connections',
    component: ConnectionsView,
    meta : {
      needsAuth: true
    }
  },
  {
    path: '/algos',
    name: 'algos',
    component: AlgosView,
    meta : {
      needsAuth: true
    }
  },
  {
    path: '/profile',
    name: 'profile',
    component: ProfileView,
    meta : {
      needsAuth: true
    }
  },

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next)=>{
  if(to.meta.needsAuth){
    if(store.state.email){
      next();
    } else {
      next("/login");
    }
  } else{
    next();
  }
})

export default router
